import * as React from 'react';
import { TextField, Autocomplete } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {
    const { data, name, label, handleChange, value, showPhone } = props
    return (
        <Autocomplete
      options={data}
      getOptionLabel={(option) =>
        option?.name ? `${option.name} ${showPhone ? `(${option.phone})` : ""}` : option
      }
      value={data.find((item) => item._id === value) || null}
      onChange={(event, newValue) =>
        handleChange({ target: { name, value: newValue?._id ?? "" } })
      }
      
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
    />
    );
}