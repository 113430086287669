import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { apis } from "../../apis";
import { get_facility } from "../../redux/slice/facility";
import { get_device } from "../../redux/slice/device";
import EnhancedTable from "./EnhancedTable";
import "../../module_css/caregivertable.css";
import { TbEdit } from "react-icons/tb";
import { LuTrash2 } from "react-icons/lu";

const columns = [
  { id: "id", label: "Sr No", isSort: true },
  { id: "device_name", label: "Device Name", isSort: true },
  { id: "device_id", label: "Device ID", isSort: false },
  { id: "facility", label: "Facility", align: "center", isSort: true },
  { id: "action", label: "Action", align: "center", isSort: false },
];

export default function DeviceTable() {
  const [updateModel, setUpdateModel] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [formData, setFormData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const facility = useSelector((state) => state.facility);
  const device = useSelector((state) => state.device.device_data) || [];
  
  useEffect(() => {
    if (!facility?.status) dispatch(get_facility({ token }));
    
  }, [facility?.status]);
  useEffect(() => {
    dispatch(get_device({ token }))
    
  },[]);

  useEffect(() => {
    if (device && device?.data?.length > 0) {
      const dataRows = device?.data?.map((it, index) => ({
        id: index + 1,
        device_name: it?.device_name,
        device_id: it?.device_id,
        facility: it?.facility?.name,
        action: (
          <>
            <button className="btn" onClick={() => handleModel(it)}>
              <TbEdit />
            </button>
            <button className="btn" onClick={() => openDeleteModal(it._id)}>
              <LuTrash2 />
            </button>
          </>
        ),
      }));
      setRows(dataRows);
      setIsSkeleton(false);
    } else {
      setRows([]);
      setIsSkeleton(true);
    }
  }, [device]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if (!formData?.device_id?.trim()) {
      toast.error("Device ID is required");
      return false;
    }
    if (!formData?.device_name) {
      toast.error("Device name is required");
      return false;
    }
    if (!formData?.facility) {
      toast.error("Facility is required");
      return false;
    }
    return true;
  };

  const openDeleteModal = (id) => {
    setDeleteId(id);
    setConfirmDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!deleteId) return;

    setLoading(true);
    try {
      await axios.delete(apis.DELETE_DEVICES, {
        data: { _id: deleteId },
        headers: { token: token },
      });

      toast.success("Device successfully deleted.", { position: "top-right" });

      setConfirmDeleteModal(false);
      setTimeout(() => {
        dispatch(get_device({ token }));
      }, 200);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  function handleModel(item) {
    setFormData(item);
    setUpdateModel(true);
  }
  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const updata = await axios.put(
        apis.UPDATE_DEVICES,
        {
          _id: formData._id,
          device_name: formData?.device_name,
          device_id: formData?.device_id,
          facility: formData?.facility,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      setUpdateModel(false);
      toast.success("Device successfully updated.", { position: "top-right" });
      setFormData({});
      setTimeout(() => {
        dispatch(get_device({ token }));
      }, 200);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const filteredRows = rows.filter((row) =>
    [row.device, row.device_id, row.facility, row.device_name].some((field) =>
      field?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <div className="container text-end mb-2">
        <TextField
          label="Search"
          type="search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            setPage(0);
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <EnhancedTable
        status={device?.status}
        columns={columns}
        rows={filteredRows}
        page={page}
        setPage={setPage}
      />
      <Modal
        show={confirmDeleteModal}
        onHide={() => setConfirmDeleteModal(false)}
        centered
      >
        <Modal.Body>
          <p className="d-flex justify-content-center" style={{fontSize:"20px", fontWeight:"bold"}}>Are you sure you want to delete this device?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete} disabled={loading}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={updateModel}
        onHide={() => setUpdateModel(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 text-center ">
              <TextField
                fullWidth
                label="Device Name"
                name="device_name"
                value={formData?.device_name || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-12 text-center ">
              <TextField
                fullWidth
                label="Device Id"
                name="device_id"
                value={formData?.device_id || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-12 text-center ">
              <Autocomplete
                options={
                  Array.isArray(facility?.facility_data)
                    ? [...facility?.facility_data].sort((a, b) =>
                        a?.name?.localeCompare(b?.name)
                      )
                    : []
                }
                getOptionLabel={(option) => option?.name || ""}
                value={formData?.facility || null}
                onChange={(event, newValue) => {
                  handleSelect({ name: "facility", value: newValue || null });
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField {...params} label="Facility" variant="outlined" />
                )}
              />
            </div>
          </div>
          {/* <TextField fullWidth label="Facility" name="device_id" value={formData?.facility[0]?.name || ''} onChange={handleChange} className="mt-3" /> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center container">
          <Button className="bg-dark-pink" onClick={handleUpdate} disabled={loading}>
            {loading ? "Updating..." : "Update Device"}
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
