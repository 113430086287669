import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apis } from "../../apis";
import axios from "axios";

export const get_client = createAsyncThunk('get_client', async ({ token }, { rejectWithValue }) => {
    try {
        let headers = {
            token: token
        };
        const { data } = await axios.get(`${apis.GET_CLIENT_CREDENTIAL}`, { headers });
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

const client = createSlice({
    name: 'get_client',
    initialState: {
        status: false,
        loading: false,
        error: '',
        client_data: [], 
    },
    extraReducers: (builder) => {
        builder.addCase(get_client.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(get_client.fulfilled, (state, action) => {
            state.client_data = action.payload;  
            state.status = true;
            state.loading = false;
            state.error = '';
        });
        builder.addCase(get_client.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.client_data = []; 
        });
    }
});

export default client.reducer;
