import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import axios from "axios";
import { apis } from "../../apis";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import MultipleSelect from "../../components/input/MultipleSelect";
import SelectSingle from "../../components/input/SingleSelect";
import Cookies from "js-cookie";
import { updateUserState, get_user } from "../../redux/slice/user";
import { toast } from "react-toastify";
import { get_company } from "../../redux/slice/createcompany";
import { get_facility } from "../../redux/slice/facility";
import { get_roles } from "../../redux/slice/roles";
import TableSection from "./table";
import { get_agency } from "../../redux/slice/agency";
import { get_Company_UserData } from "../../redux/slice/company_user_data";
import EnhancedTable from "./EnhancedTable";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const columns = [
    { id: "id", label: "Sr No", isSort: true },
    { id: "name", label: "Name", isSort: true },
    // { id: "company", label: "Company", isSort: true },
    { id: "roles", label: "Role", isSort: true },
    { id: "email", label: "Email", align: "center", isSort: true },
    // { id: "portal", label: "Agency Tracking", isSort: false },
    // { id: 'mail_report', label: 'Mail Report', align: 'end', isSort: false },
    // {
    //     id: "subscribe",
    //     label: "Patient Communication",
    //     align: "end",
    //     isSort: false,
    // },
    { id: "action", label: "Action", align: "center", isSort: false },
];

export default function CompanyUserTable() {
    const [Loading, setLoading] = useState(false);
    const [updateModel, setUpdateModel] = useState(false);
    const [FormData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userEmail, setUserEmail] = useState("")
    // const facility = useSelector((state) => state.facility);
    // const user = useSelector((state) => state.user);
    // const company = useSelector((state) => state.company);
    // const roles = useSelector((state) => state.roles);
    // const agency = useSelector((state) => state.agency);
    const company_user_data = useSelector((state) => state.getCompanyUserData?.company_user_data) // new add
    const { status } = useSelector((state) => state.getCompanyUserData)
    const dispatch = useDispatch()

    const token = Cookies.get("token");
    const [rows, setRows] = useState([]);
    const [isSkeleton, setIsSkeleton] = useState(true);
    const profile = useSelector((state) => state.profile);
    const [isToggleLoader, setIsToggleLoader] = useState({
        id: "",
        email: false,
        portal: false,
        subscribe: false,
    });
    const [validationErrors, setValidationErrors] = useState({
        company: "",
    });
    const [page, setPage] = useState(0);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    useEffect(() => {
        if (!profile?.status) {
            dispatch(get_agency({ token, user: profile?.data?._id }));
        }
        if (!company_user_data?.status) {

            dispatch(get_Company_UserData({ token, company_id: profile?.data?.company }))
        }

    }, [token, profile]);


    useEffect(() => {
        const dataRows = Array.isArray(company_user_data?.users)
            ? company_user_data?.users?.map((it, key) => {
                return {
                    // id: company_user_data?.users?.length - key,
                    name: it?.first_name || it?.last_name ? `${it?.first_name} ${it?.last_name}` : "N/A",
                    roles: it?.roles[0]?.name
                        ? it.roles[0].name.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
                        : "N/A",

                    email: it?.email ? it?.email : "N/A",
                    portal: (
                        <div className="form-check form-switch d-flex justify-content-center">
                            <input
                                style={{ cursor: "pointer" }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                onChange={() => handalPortalAccess(it)}
                                id="flexSwitchCheckDefault"
                                checked={it?.portal_access}
                            />
                        </div>
                    ),
                    action: (
                        <>
                            <button className="btn" onClick={() => handleModel(it)} >
                                <TbEdit />
                            </button>
                            
                        </>
                    ),
                };
            }) : [];
        setRows(dataRows?.reverse());
    }, [company_user_data?.users?.length]);

    const handleChangeModal = ({ target }) => {
        const { name, value } = target;

        if (name === "newPassword") {
            setNewPassword(value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
    };

    function handleModel(item) {
        setUserEmail(item?.email)
        setUpdateModel(true);
    }


    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleUpdate = async () => {
        if (!newPassword) {
            return toast.error("New password is required.", { position: "top-right" });
        }
        if (!confirmPassword) {
            return toast.error("Confirm password is required.", { position: "top-right" });
        }
        if (newPassword !== confirmPassword) {
            return toast.error("Passwords do not match.", { position: "top-right" });
        }

        try {
            setLoading(true);
            
            await axios.put(
                apis.COMPANY_MANAGER_UPDATE_USER_PASSWORD,
                {
                    userEmail: userEmail,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword
                },
                {
                    headers: { token: token },
                }
            );

            setUpdateModel(false);
            toast.success("Password updated successfully.", { position: "top-right" });
        } catch (error) {
            toast.error(error?.response?.data?.msg || error?.message, { position: "top-right" });
        } finally {
            setLoading(false);
        }
    };


    const filteredRows = [...rows]
        .filter((row) => {
            const searchFields = [row.name, row.company, row.roles, row.email];
            return searchFields.some((field) =>
                field?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            );
        })
        .map((row, index) => ({
            ...row,
            id: index + 1,
        }));

    return (
        <>
            <div className="container text-end mb-2 searchuser">
                <TextField
                    label="Search"
                    type="search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => {
                        setPage(0);
                        setSearchQuery(e.target.value);
                    }}
                />
            </div>
            {/* )} */}
            <EnhancedTable
                status={status}
                columns={columns}
                rows={filteredRows}
                page={page}
                setPage={setPage}
            />

            <Modal
                show={confirmDeleteModal}
                onHide={() => setConfirmDeleteModal(false)}
                centered
            >
                <Modal.Body>
                    <p className="d-flex justify-content-center" style={{ fontSize: "20px", fontWeight: "bold" }}>Are you sure you want to delete this user?</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={updateModel}
                onHide={() => {
                    setUpdateModel(false);
                    setConfirmPassword("");
                    setNewPassword("");
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row userdrow">
                        <div className="col-md-6 mb-6 text-center ttfield usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="New Password"
                                value={newPassword}
                                onChange={handleChangeModal}
                                name="newPassword"
                                type={showNewPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-6 text-center usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Confirm Password"
                                value={confirmPassword}
                                onChange={handleChangeModal}
                                name="confirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {Loading ? (
                            <button className="btn btn-success bg-dark-pink" type="button">
                                Loading...
                            </button>
                        ) : (
                            <button className="btn btn-success bg-dark-pink" onClick={handleUpdate}>
                                Update Password
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}