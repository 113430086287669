function processShifts(shiftsData) {
    const groupedShifts = {};

    // Group shifts by agency_shift_id and caregiver._id
    shiftsData.forEach(shift => {
        const key = `${shift.agency_shift_id}`;
        if (!groupedShifts[key]) {
            groupedShifts[key] = { wholeShifts: [], inShifts: [], outShifts: [] };
        }

        if (shift.shift_in_id && shift.shift_out_id) {
            groupedShifts[key].wholeShifts.push(shift);
        } 
        if (shift.shift_in_id && !shift.shift_out_id) {
            groupedShifts[key].inShifts.push(shift);
        } 
        if (shift.shift_out_id && !shift.shift_in_id) {
            groupedShifts[key].outShifts.push(shift);
        }
    });

    const finalShifts = [];

    Object.values(groupedShifts).forEach(({ wholeShifts, inShifts, outShifts }) => {
        // Push all complete shifts as they are
        if(wholeShifts?.length){
            finalShifts.push(...wholeShifts);
        }else{
            if (inShifts.length && outShifts.length) {
                // Sort to get the latest inShift and outShift
                inShifts.sort((a, b) => new Date(b.shift_in_id?.utcTime) - new Date(a.shift_in_id?.utcTime));
                outShifts.sort((a, b) => new Date(b.shift_out_id?.utcTime) - new Date(a.shift_out_id?.utcTime));
    
                const latestInShift = inShifts[0];
                const latestOutShift = outShifts[0];
               
    
                // Update the outShift with shift_in_id to create a complete shift
                latestOutShift.shift_in_id = latestInShift.shift_in_id;
                latestOutShift.shift_started = latestInShift.shift_started;
                latestOutShift.startShift = latestInShift.startShift;
                finalShifts.push(latestOutShift);
                finalShifts.push(...inShifts.slice(1));
            }else{
                finalShifts.push(...inShifts);
                finalShifts.push(...outShifts);
            }
        }

    });

    return finalShifts;
}

export default processShifts;