import React, { useEffect, useState,useRef } from "react";
import { CiExport } from "react-icons/ci";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { get_all_csv_shifts } from "../../../redux/slice/exportcsvdata/exportshifts";
import { convertToMMDDYYYYWithTime } from "../../../utils/Helper";

//function to get current date and time
function formatDate() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}-${formattedHours}:${minutes}${ampm}`;
  return formattedDate;
}

const SpecificFieldExportJSON = ({
  data,
  selectedField = [],
  isSpecifiedFields = false,
  setIsSpecificFieldsModal, handalFilter, searchFilter
}) => {
  const [csvData, setCsvData] = useState([]);
  const [filename, setFileName] = useState("");
  const all_csv_shifts = useSelector((state) => state.all_csv_shifts);
  const [isLoading, setIsLoading] = useState(false);
  const csvLinkRef = useRef(null);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const dispatch = useDispatch()
  const headers = [
    "ClassName",
    "EmployeeId",
    "FirstName", 
    "LastName",
    "Email",
    "ScanInTime",
    "ScanOutTime",
    "Scan duration",
    "CheckInTime(MM/DD/YYYY)",
    "CheckOutTime(MM/DD/YYYY)",
    "ManualCheckOutTime",
    "HoursInFacility",
    "Phone",
    "Address",
    "VisitType",
    "ResidentName",
    "RoomNumber",
    "KioskName",
    "ShiftId",
    "FacilityId",
    "LocationId",
    "AgencyId",
    "Agency",
    "ShiftTime",
    "PunchInString",
    "PunchOutString",
    "JobFunction",
    "Vendor",
    "Department",
    "Vaccination",
    "CheckInTemperature",
    "CheckOutTemperature",
    "MidShiftTemperatures",
    "Do you have fever or symptoms of a respiratory infection, such as a cough and sore throat?",
    "Have you traveled internationally within the last 14 days to restricted countries?",
    "Have you had contact with someone with known or suspected COVID-19?",
  ];
  useEffect(() => {
    const dataList = data?.map((it) => {
      return {
        ClassName: it?.facility_id?.name,
        // ShiftId: it?._id,
        // FullName: `${it?.caregiver?.lastName} ${it?.caregiver?.firstName}`,
        // EmployeeId:`${it?.caregiver?._id}`,
        FirstName: `${it?.caregiver?.firstName}`,
        LastName: `${it?.caregiver?.lastName}`,
        Email: "",
        ScanInTime:convertToMMDDYYYYWithTime(it?.scan_time),
        ScanOutTime:it?.scan_out_time ? convertToMMDDYYYYWithTime(it?.scan_out_time) : it?.shift_out_id?.utcTime ? convertToMMDDYYYYWithTime(it?.shift_out_id?.utcTime): "N/A",
        "CheckInTime(MM/DD/YYYY)": it?.startShift?.date && it?.startShift?.time ? `${it?.startShift?.date} : ${it?.startShift?.time}` : "N/A",
        "CheckOutTime(MM/DD/YYYY)": it?.endShift?.date && it?.endShift?.time ? `${it?.endShift?.date} : ${it?.endShift?.time}` : "N/A",
        ManualCheckOutTime: "",
        HoursInFacility: `${it?.duration}`,
        Phone: it?.caregiver?.phone,
        Address: it?.facility_id?.address,
        VisitType: "agency",
        ResidentName: "",
        RoomNumber: "",
        KioskName: it?.facility_id?.name,
        ShiftId: it?.agency_shift_id,
        FacilityId:it?.facility_id?._id,
        LocationId:it?.facility_id?.locationId,
        AgencyId:it?.agency?._id,
        Agency: it?.agency?.name,
        ShiftTime: "",
        PunchInString:it?.shift_in_id?.punch_string,
        PunchOutString:it?.shift_out_id?.punch_string,
        JobFunction: it?.position,
        Vendor: "",
        Department: "",
        Vaccination: "",
        CheckInTemperature: "",
        CheckOutTemperature: "",
        MidShiftTemperatures: "",
        "Do you have fever or symptoms of a respiratory infection, such as a cough and sore throat?":
          "",
        "Have you traveled internationally within the last 14 days to restricted countries?":
          "",
        "Have you had contact with someone with known or suspected COVID-19?":
          "",
      };
    });

    const specificFieldDataList = data?.map((it) => {
      return {
        "Employee id": it?.caregiver?.agencyEmployeeId || "N/A",
        "Facility name": it?.facility_id?.name || "N/A",
        "First Name": `${it?.caregiver?.firstName}`||"N/A",
        "Last Name": `${it?.caregiver?.lastName}` ||"N/A",
        "Scan In Time":convertToMMDDYYYYWithTime(it?.scan_time) || "N/A",
        "Scan Out Time":it?.scan_out_time ?  convertToMMDDYYYYWithTime(it?.scan_out_time ) : it?.shift_out_id?.utcTime ? convertToMMDDYYYYWithTime(it?.shift_out_id?.utcTime): "N/A" ,
        "Scan duration":it?.scan_duration || "N/A",
        "Shift id": it?.agency_shift_id || "N/A",
        "Check in": it?.startShift?.date && it?.startShift?.time
          ? `${it.startShift.date} : ${it.startShift.time}`
          : "N/A",
        "Check out": it?.endShift?.date && it?.endShift?.time
          ? `${it.endShift.date} : ${it.endShift.time}`
          : "N/A",
        "Facility Id":it?.facility_id?._id ||"N/A",
        "Location Id":it?.facility_id?.locationId ||"N/A",
        "Agency Id":it?.agency?._id ||"N/A",
        "Facility hours": it?.duration ? `${it.duration}` : "N/A",
        Phone: it?.caregiver?.phone || "N/A",
        Address: it?.facility_id?.address || "N/A",
        Agency: it?.agency?.name || "N/A",
        "Shift time": "",
        Position: it?.position || "N/A",
        "Punch In String":it?.shift_in_id?.punch_string || "N/A",
        "Punch Out String":it?.shift_out_id?.punch_string || "N/A",
      };
    });



    if (data?.length) {
      const date = formatDate();
      const file = `Report-${date}.csv`;
      setFileName(file);
      setCsvData(isSpecifiedFields ? specificFieldDataList : dataList);
    } else {
      const date = formatDate();
      const file = `Report-${date}.csv`;
      setFileName(file);
      setCsvData("no-data.csv");
    }
  }, [data]);

  const handleExportCsv = async () => {
    setIsLoading(true);
    try {
      const filter = handalFilter(searchFilter, 0);
      await dispatch(get_all_csv_shifts(filter));
      setIsReadyToDownload(true);
      setTimeout(() => {
        setIsSpecificFieldsModal(false);
      }, 500);
     
    } catch (error) {
      console.error("err", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (isReadyToDownload && csvLinkRef.current) {
      csvLinkRef.current.link.click(); 
      setIsReadyToDownload(false); 
    }
  }, [isReadyToDownload]);
  
  return (

    // <CSVLink
    //   data={csvData}
    //   headers={isSpecifiedFields ? selectedField : headers}
    //   filename={filename}
    //   style={{ textDecoration: "none" }}
    // >
    //   <div className="date-fltr ">
    //     {isSpecifiedFields ? <span className="p-2" onClick={() => setIsSpecificFieldsModal(false)}>
    //       <i className="px-2">
    //         <CiExport />
    //       </i>
    //       Export
    //     </span> :
    //       <span className="p-2">
    //         <i className="px-2">
    //           <CiExport />
    //         </i>
    //         Export
    //       </span>
    //     }

    //   </div>
    // </CSVLink>
    <>
    {isSpecifiedFields ? (
       <div className="date-fltr">
       <span
         className={`p-2 ${selectedField.length > 0 ? "cursor-pointer" : "opacity-50 "}`}
         onClick={() => {
           if (isLoading || selectedField.length === 0) return;
           handleExportCsv();
         }}
       >
         <i className="px-2">
           <CiExport />
         </i>
         {isLoading ? "Loading..." : "Export"}
       </span>
     </div>
     
      ) : (
        <div className="date-fltr">
          <span
            className="p-2"
            onClick={handleExportCsv}
            disabled={isLoading}
          >
            <i className="px-2">
              <CiExport />
            </i>
            {isLoading ? "Loading..." : "Export"}
          </span>
        </div>
      )}

      {/* Hidden CSVLink */}
      <CSVLink
        data={csvData}
        headers={isSpecifiedFields ? selectedField : headers}
        filename={filename}
        ref={csvLinkRef}
        style={{ display: "none" }} // Hide the link
      />
    </>
  )

};

export default SpecificFieldExportJSON;
