import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React, { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdCalendarMonth } from "react-icons/md";
import { PiArrowClockwiseBold, PiExportLight } from "react-icons/pi";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment-timezone";
import { DateRange } from "react-date-range";
import { Autocomplete, Chip, IconButton, InputAdornment, Skeleton, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_agency } from "../../redux/slice/agency";
import Cookies from "js-cookie";
import { get_shift } from "../../redux/slice/caregiver_with_shift";
import ExportJSON from "./export";
import AuthPunch from "./punch/";
import { uniqueArrayOfObject } from "../../helper/object";
import { get_facility } from "../../redux/slice/facility";
import { get_all_caregivers } from "../../redux/slice/caregivers";
import { get_all_csv_shifts } from "../../redux/slice/exportcsvdata/exportshifts";
import SelectSingle from "../input/Select";
import "../../module_css/Shift_filter_page.css";
import { get_company } from "../../redux/slice/createcompany";
import useDebounce from "../../helper/useDebounce";


import SpecificFields from "./export/SpecificFields";
import CloseIcon from '@mui/icons-material/Close';
import { BiShow } from "react-icons/bi";

const positionList = [
  {
    _id: "CNA",
    value: "CNA",
  },
  {
    _id: "LPN",
    value: "LPN",
  },
  {
    _id: "RN",
    value: "RN",
  },
];
const punchTypeList = [
  {
    _id: "Normal Punch",
    value: "Normal Punch",
  },
  {
    _id: "Kiosk Punch",
    value: "Kiosk Punch",
  },
];

const filterData = (data) => {
  return data?.map((it) => {
    return {
      _id: it._id,
      value: it?.name,
    };
  });
};
const caregiverfilter = (data) => {
  return data?.map((it) => {
    return {
      _id: it._id,
      value: `${it?.lastName} ${it?.firstName}`,
    };
  });
};
const ShiftFilter = ({ isSkeleton, setIsSkeleton, setPage, page, limit, setHideColumn, setFilterColumn }) => {
  const [isSpecificFieldsModal, setIsSpecificFieldsModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const agency = useSelector((state) => state.agency);
  const company = useSelector((state) => state.company);
  const shift = useSelector((state) => state.shift);
  const profile = useSelector((state) => state.profile);
  const facility = useSelector((state) => state.facility);
  const all_caregivers = useSelector((state) => state.all_caregivers);
  const all_csv_shifts = useSelector((state) => state.all_csv_shifts);
  const [handleModal, setHandleModal] = useState(false)
  const [empSearch, setEmpSearch] = useState("");

  const [FormData, setFormData] = useState({
    company: ""

  });
  const [filteredFacilities, setFilteredFacilities] = useState([]);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const listInit = {
    _id: "",
    value: "",
  };

  const debouncedEmpSearch = useDebounce(empSearch, 500);



  const [agencyList, setAgencyList] = useState([listInit]);
  const [facilityList, setFacilityList] = useState([]);

  const [employee, setEmployee] = useState([listInit]);
  const [confirmed, setConfirmed] = useState(false);

  const handleConfirm = () => {
    setConfirmed(true);
    const modal = document.getElementById("exportModal");
    // modal.querySelector(".btn-close").click();

  };

  const uniqueEmployeeName = employee?.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value)
  );

  // filter agency
  function sortArrayByValue(array) {
    array?.sort((a, b) => {
      const valueA = a.value.toLowerCase();
      const valueB = b.value.toLowerCase();
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });
    return array;
  }

  useEffect(() => {

    if (!facility?.status && !facility?.loading) {
      dispatch(get_facility({ token }));
    }
    if (!company?.status) {
      dispatch(get_company({ token }));
    }
    if (profile?.data?.roles[0]?.name == "admin") {
      const list = filterData(facility?.facility_data);
      setFacilityList(sortArrayByValue(list));
    } else if (
      profile?.data?.roles[0]?.name == "agency_user" &&
      !facilityList?.length
    ) {
      const facility = shift?.shift?.map((item) => {
        return { ...item.facility_id };
      });
      const uuniqueList = uniqueArrayOfObject(facility, "_id");
      const list = filterData(uuniqueList);
      setFacilityList(sortArrayByValue(list));
    } else if (
      profile?.data?.roles[0]?.name == "normal" &&
      !facilityList?.length
    ) {
      const list = filterData(profile?.data?.facility);
      setFacilityList(sortArrayByValue(list));
    } else {
      const list = filterData(facility?.facility_data);
      setFacilityList(sortArrayByValue(list));
    }
  }, [profile, facility]);

  const handleChangeModal = ({ target }) => {
    const { name, value } = target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };


  useEffect(() => {
    if (FormData?.company) {
      const filtered = facility?.facility_data?.filter(fac => fac?.company?._id === FormData.company);

      const names = filtered?.map(fac => fac?.name) || [];

      const preSelectedFacilities = facilityList?.filter(fac => names.includes(fac.value)) || [];

      setFilteredFacilities(preSelectedFacilities);
      handalSearchById("facility", preSelectedFacilities);
    }
  }, [FormData]);

  useEffect(() => {
    if (
      agency?.status &&
      profile?.status &&
      positionList?.length &&
      shift.status
    ) {
      setIsSkeleton(false);
    }
  }, [agencyList, facilityList, positionList, uniqueEmployeeName, shift]);
  // shift
  useEffect(() => {
    if (!all_caregivers.status && !all_caregivers.loading) {
      dispatch(get_all_caregivers({ token }));
    }
    if (all_caregivers?.status) {
      const list = caregiverfilter(all_caregivers?.data);

      setEmployee(sortArrayByValue(list));
    }
  }, [all_caregivers.status]);

  useEffect(() => {
    const list = filterData(agency.agency);
    setAgencyList(sortArrayByValue(list));
    if (!agency.status && !agency?.loading) {
      dispatch(get_agency({ token, user: profile?.data?._id }));
    }
  }, [agency.status]);

  const formInit = {
    emp_search: listInit,
    startdate: "",
    enddate: "",
    position: listInit,
    facility: listInit,
    agency: listInit,
  };
  const [searchFilter, setSearchFilter] = useState("");

  // date handal
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handalFilter = (searchFilter, page) => {
    return {
      token,
      user: profile?.data?._id,
      position: searchFilter?.position?._id ? searchFilter?.position?._id : "",
      startdate: searchFilter?.startdate ? searchFilter?.startdate : "",
      enddate: searchFilter?.enddate ? searchFilter?.enddate : "",

      agency_id: searchFilter?.agency?.length
        ? searchFilter.agency.map((ag) => ag._id)
        : "",

      facility:
        searchFilter?.facility?.length > 0
          ? searchFilter.facility.map((fac) => fac._id)
          : "",
      // search: searchFilter?.emp_search?.value?.trim()
      //   ? searchFilter?.emp_search?.value?.trim()
      //   : "",
      search: debouncedEmpSearch,
      punchtype: searchFilter?.punchtype?._id
        ? searchFilter?.punchtype?._id
        : "",
      page: page,
      // debouncedEmpSearch: debouncedEmpSearch,
      limit: limit,
    };
  };


  useEffect(() => {
    const newPage = 0;
    setPage(newPage);
    const filter = handalFilter(searchFilter, newPage);
    dispatch(get_shift(filter));
  }, [searchFilter, profile?.status, limit, debouncedEmpSearch]);

  useEffect(() => {
    if (page !== 0) {
      const filter = handalFilter(searchFilter, page);
      dispatch(get_shift(filter));
    }
  }, [page, debouncedEmpSearch]);



  const handleExport = async () => {
    try {
      setExportLoading(true)
      setLoading(true);
      const filter = handalFilter(searchFilter, 0);
      await dispatch(get_all_csv_shifts(filter));
    } catch (err) {
      console.error(err)
    }
    finally {
      setLoading(false);
      setExportLoading(false)
    }
  };

  //  const handleExportClick = async () => {
  //   await handleExport(); // Fetch data
  //  // setIsSpecificFieldsModal(true); // Open modal after API response
  // };

  // handal Date
  const handleDate = (ranges) => {
    // const End = new Date(
    //   new Date(ranges.selection.endDate).getTime() + 86400000
    // );
    const stdate = new Date(ranges.selection.startDate);
    const endate = new Date(ranges.selection.endDate);
    setSelectionRange(ranges.selection);
    setSearchFilter({
      ...searchFilter,
      startdate: moment(stdate).format("YYYY-MM-DD"),
      enddate: moment(endate).format("YYYY-MM-DD"),
    });
  };

  //  Searching
  const handalSearch = (name, value) => {
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });

  };

  const handalSearchById = (name, value) => {
    console.log("value",name)
    if (name === "emp_search"){
      setEmpSearch(value)

    }
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });
  };



  // clear filter
  const handalClearFilter = () => {
    setEmpSearch("")
    setSearchFilter("");
    setFormData('');
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
  };
  // handle csv export api
  const handleExportCsv = async () => {
    setLoading(true)
    try {
      const filter = handalFilter(searchFilter, 0);
      await dispatch(get_all_csv_shifts(filter))

    } catch (error) {
      console.error("err", error)
    } finally {
      setLoading(false)
    }

  }

  return (
    <div className="">
      {/* <div onClick={handleExport} >export </div> */}
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="filter-left heading">
          {isSkeleton ? (
            <div className="py-2">
              <Skeleton variant="rounded" width={140} height={30} />
            </div>
          ) : (
            <h4>Agency Validation Scans</h4>
          )}
          <div className="d-flex align-items-center mb-3">
            {isSkeleton ? (
              <Skeleton variant="rounded" width={220} height={26} />
            ) : (
              <div>Here is a list of all the shifts</div>
            )}
          </div>
        </div>
        <div className="d-flex custom">
          {/* Hide column*/}
          <div className="date-pos">
            {isSkeleton ? (
              <div className="px-2">
                <Skeleton variant="rounded" width={100} height={36} />
              </div>
            ) : (
              <>
                <div className="date-fltr"
                  onClick={() => {
                    setHideColumn(true)
                    setFilterColumn(true)
                  }}>
                  <span>
                    <i className="px-1" style={{ color: "#4C7153" }}>
                      <BiShow />
                    </i>
                  </span>
                </div>
              </>
            )}
          </div>
          {/* date section  */}
          <div className="date-pos">
            {!isSkeleton ? (
              <>
                <div className="date-fltr">
                  <span>
                    <MdCalendarMonth />
                    {searchFilter?.startdate && searchFilter?.enddate ? (
                      <span className="date-icon-span">
                        {searchFilter?.startdate} / {searchFilter?.enddate}
                      </span>
                    ) : (
                      <span className="date-icon-span">Date</span>
                    )}
                  </span>
                </div>
                <div className="col-xl-3 button  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0" style={{ position: "relative" }}>
                  {showTooltip && (
                    <div
                      className="custom-tooltip"
                      style={{
                        position: "absolute",
                        top: "-65px",
                        transform: "translateX(-50%)",
                        background: "gray",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        zIndex: 10,
                      }}
                    >
                      Filters data based on UTC timezone
                    </div>
                  )}

                  <Dropdown className="dash-main-filter"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <Dropdown.Toggle
                      style={{ height: "100%", width: "183%" }}
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DateRange
                        ranges={[selectionRange]}
                        onChange={handleDate}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <Skeleton variant="rounded" width={100} height={36} />
            )}
          </div>

          {/* clear filter  */}
          <div className="date-pos">
            {isSkeleton ? (
              <div className="px-2">
                <Skeleton variant="rounded" width={100} height={36} />
              </div>
            ) : (
              <>
                <div className="date-fltr" onClick={handalClearFilter}>
                  <span>
                    <i className="px-1" style={{ color: "#4C7153" }}>
                      <PiArrowClockwiseBold />
                    </i>
                    Clear filter
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="date-pos">
            {isSkeleton ? (
              <div className="px-2">
                <Skeleton variant="rounded" width={100} height={36} />
              </div>
            ) : (
              <>
                {/* <div className="date-fltr" onClick={() => {
                  handleExportCsv();
                  setHandleModal(true);
                }}>
                  <span>
                    <i className="px-1" style={{ color: "#4C7153" }}>
                      <PiExportLight />
                    </i>
                    Export tt
                  </span>
                </div> */}
                <div className=" date-fltr">
                </div>

                {
                  handleModal && (
                    <div className="modal fade show d-block" id="exportModal" tabIndex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                          <div className="modal-body  p-4 fs-4 d-flex justify-content-center fw-bold">
                            Are you sure you want to export the data?
                          </div>
                          <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn px-4 btn-secondary" onClick={() => setHandleModal(false)} data-bs-dismiss="modal">Cancel</button>

                            <button type="button" className="btn" onClick={() => setHandleModal(false)} disabled={loading}>
                              {loading ? (<span>Loading...</span>) : <ExportJSON data={all_csv_shifts?.csvdata?.data} />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>


                  )
                }
                {handleModal && <div className="modal-backdrop fade show" onClick={() => setHandleModal(false)}></div>}

              </>
            )}
          </div>
          <div className="date-pos">
            {isSkeleton ? (
              <div className="px-2">
                <Skeleton variant="rounded" width={100} height={36} />
              </div>
            ) : (
              <p
                style={{
                  // color: "#4C7153",
                  border: "1px solid #dfdfdf",
                  padding: "5px 12px",
                  borderRadius: "5px",
                  color: "#7b7b7b",
                  cursor: "pointer",
                  marginTop: "-6px",
                }}
                onClick={() => setIsSpecificFieldsModal(true)}

              >
                {<> <PiExportLight /> Export</>}
              </p>
            )}
          </div>


          {/* add punch  */}
          {profile?.data?.roles[0]?.name !== "agency_user" && (
            <>
              <div className="date-pos">
                {isSkeleton ? (
                  <div className="px-2">
                    <Skeleton variant="rounded" width={100} height={36} />
                  </div>
                ) : (
                  <>
                    <AuthPunch filter={searchFilter} />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="filter-right d-flex align-items-center justify-content-center flex-wrap">
        <div className="">
          {isSkeleton ? (
            <Skeleton variant="rounded" width={100} height={36} />
          ) : (
            <>
              <CiFilter size={23} />
              <span className="filter-icon-span">Filter by:</span>
            </>
          )}
        </div>
        {/* employee search  */}
        {/* <div className="px-2 auto">
          {!isSkeleton ? (
            <Autocomplete
              id="size-small-filled"
              size="small"
              options={uniqueEmployeeName}
              sx={{ width: 260 }}
              value={
                searchFilter ? searchFilter?.emp_search : { id: "", value: "" }
              }
              defaultValue={
                searchFilter ? searchFilter?.emp_search : { id: "", value: "" }
              }
              selectOnFocus
              onChange={(_, value) => handalSearchById("emp_search", value)}
              getOptionLabel={(option) => option.value}
              renderInput={(params, id) => (
                <TextField {...params} label="Search Employee"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: searchFilter?.emp_search && searchFilter?.emp_search?.value ? (
                      <InputAdornment position="end" style={{ position: "absolute", right: "0" }} >
                        <IconButton onClick={() => handalSearchById("emp_search", { id: "", value: "" })}>
                          <CloseIcon style={{ fontSize: "18px" }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}

                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ cursor: "pointer" }}
                  key={option._id}
                  className="py-1 ps-3 menu-drop-item"
                >
                  {" "}
                  {option.value}{" "}
                </li>
              )}
            />
          ) : (
            <Skeleton variant="rounded" width={210} height={36} />
          )}
        </div> */}
        <div className="px-2 auto">
          {!isSkeleton ? (
            <TextField
              id="employee-search"
              size="small"
              sx={{ width: 260 }}
              label="Search Employee"
              value={empSearch || ""}
              onChange={(e) => setEmpSearch(e.target.value)}
            // InputProps={{
            //   endAdornment:
            //     setEmpSearch?.length > 0 ? (
            //       <InputAdornment position="end">
            //         <IconButton
            //           onClick={() => handalSearchById(setEmpSearch(''))}
            //         >
            //           <CloseIcon style={{ fontSize: "18px" }} />
            //         </IconButton>
            //       </InputAdornment>
            //     ) : null,
            // }}
            />
          ) : (
            <Skeleton variant="rounded" width={260} height={36} />
          )}
        </div>



        {/* Agency section  */}
        <div className="px-2">
          {!isSkeleton ? (
            <Autocomplete
              multiple
              id="multi-select-agency"
              size="small"
              options={agencyList?.length ? agencyList : []}
              sx={{ width: 260 }}
              value={searchFilter?.agency || []}
              getOptionLabel={(option) => option.value}
              onChange={(_, value) => handalSearchById("agency", value)}
              disableCloseOnSelect
              renderOption={(props, option) => {
                const isSelected = searchFilter?.agency?.some((ag) => ag._id === option._id);
                return (
                  <li
                    {...props}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isSelected ? "#E5EEFA" : "transparent",
                      color: "black",
                    }}
                    key={option._id}
                    className="py-1 ps-3 menu-drop-item"
                  >
                    {option.value}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) => {
                const displayCount = 1;
                if (value.length > displayCount) {
                  return (
                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      {value.slice(0, displayCount).map((item, index) => (
                        <Chip
                          sx={{
                            maxWidth: "75px",
                            width: "75px",
                            maxHeight: "25px",
                            height: "18px",
                            "& .MuiChip-deleteIcon": {
                              fontSize: "15px",
                            },
                          }}
                          key={item._id}
                          label={item.value}
                          {...getTagProps({ index })}
                        />
                      ))}
                      <Chip
                        label={`+${value.length - displayCount}`}
                        sx={{
                          maxWidth: "40px",
                          width: "40px",
                          maxHeight: "25px",
                          height: "15px",
                          "& .MuiChip-deleteIcon": {
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    {value.map((item, index) => (
                      <Chip
                        sx={{
                          maxWidth: "60px",
                          width: "60px",
                          maxHeight: "25px",
                          height: "15px",
                          "& .MuiChip-deleteIcon": {
                            fontSize: "14px",
                          },
                        }}
                        key={item._id}
                        label={item.value}
                        {...getTagProps({ index })}
                      />
                    ))}
                  </div>
                );
              }}
              renderInput={(params) => (
                <Tooltip
                  title={
                    searchFilter?.agency?.length
                      ? searchFilter.agency.map((ag) => ag.value).join(", ")
                      : "No agency selected"
                  }
                  arrow
                >
                  <TextField
                    {...params}
                    label="Agency"
                    placeholder="Select Agencies"
                    InputLabelProps={{ shrink: searchFilter?.agency?.length > 0 || params.InputProps.startAdornment }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment:
                        searchFilter?.agency?.length > 0 ? (
                          <InputAdornment position="end" style={{ position: "absolute", right: "0" }}>
                            <IconButton onClick={() => handalSearchById("agency", [])}>
                              <CloseIcon style={{ fontSize: "18px" }} />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                    sx={{ maxWidth: "100%" }}
                  />
                </Tooltip>
              )}
            />
          ) : (
            <Skeleton variant="rounded" width={260} height={36} />
          )}
        </div>

        {/* Company section  */}
        {profile?.data?.roles[0]?.name == "admin" && <div className="px-2">
          {!isSkeleton ? (
            <Autocomplete
              options={company?.company_data || []}
              sx={{ width: 260 }}
              size="small"
              getOptionLabel={(option) => option.name || ""}
              value={
                company?.company_data?.find(
                  (item) => item._id === (typeof FormData?.company === "string" ? FormData?.company : FormData?.company?._id)
                ) || null
              }
              onChange={(event, newValue) => {
                handleChangeModal({
                  target: { name: "company", value: newValue ? newValue._id : "" },
                });
              }}
              renderInput={(params) => <TextField {...params} label="Company" name="company" variant="outlined" />}
            />
          ) : (
            <Skeleton variant="rounded" width={260} height={36} />
          )}
        </div>}

        {/* Facility section  */}
        {profile?.data?.roles[0]?.name !== "agency_user" && (
          <div className="px-2">
            {!isSkeleton ? (
              <Autocomplete
                multiple
                id="multi-select-facility"
                size="small"
                options={facilityList?.length ? facilityList : [listInit]}
                sx={{ width: 260 }}
                value={searchFilter?.facility || []}
                getOptionLabel={(option) => option.value}
                onChange={(_, value) => handalSearchById("facility", value)}
                disableCloseOnSelect
                renderOption={(props, option) => {
                  const isSelected = searchFilter?.facility?.some((fac) => fac._id === option._id);
                  return (
                    <li
                      {...props}
                      style={{
                        cursor: "pointer",
                        backgroundColor: isSelected ? "#E5EEFA" : "transparent",
                        color: "black",
                      }}
                      key={option._id}
                      className="py-1 ps-3 menu-drop-item"
                    >
                      {option.value}
                    </li>
                  );
                }}
                renderTags={(value, getTagProps) => {
                  const displayCount = 1;
                  if (value.length > displayCount) {
                    return (
                      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        {value.slice(0, displayCount).map((item, index) => (
                          <Chip
                            sx={{
                              maxWidth: "65px",
                              width: "65px",
                              maxHeight: "25px",
                              height: "18px",
                              "& .MuiChip-deleteIcon": {
                                fontSize: "15px",
                              },
                            }}
                            key={item._id}
                            label={item.value}
                            {...getTagProps({ index })}
                          />
                        ))}
                        <Chip
                          label={`+${value.length - displayCount}`}
                          sx={{
                            maxWidth: "40px",
                            width: "40px",
                            maxHeight: "25px",
                            height: "15px",
                            "& .MuiChip-deleteIcon": {
                              fontSize: "14px",
                            },
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      {value.map((item, index) => (
                        <Chip
                          sx={{
                            maxWidth: "60px",
                            width: "60px",
                            maxHeight: "25px",
                            height: "15px",
                            "& .MuiChip-deleteIcon": {
                              fontSize: "14px",
                            },
                          }}
                          key={item._id}
                          label={item.value}
                          {...getTagProps({ index })}
                        />
                      ))}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      searchFilter?.facility?.length
                        ? searchFilter.facility.map((fac) => fac.value).join(", ")
                        : "No facility selected"
                    }
                    arrow
                  >
                    <TextField
                      {...params}
                      label="Facility"
                      placeholder="Select Facilities"
                      InputLabelProps={{ shrink: searchFilter?.facility?.length > 0 || params.InputProps.startAdornment, }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment:
                          searchFilter?.facility?.length > 0 ? (
                            <InputAdornment position="end" style={{ position: "absolute", right: "0" }}>
                              <IconButton onClick={() => {
                                handalSearchById("facility", [])
                                setFormData('')
                              }}>
                                <CloseIcon style={{ fontSize: "18px" }} />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      sx={{ maxWidth: "100%" }}
                    />
                  </Tooltip>
                )}
              />
            ) : (
              <Skeleton variant="rounded" width={210} height={36} />
            )}
          </div>
        )}

        {/* Position section  */}
        <div className="px-2">
          {!isSkeleton ? (
            <Autocomplete
              id="size-small-filled"
              size="small"
              options={positionList}
              sx={{ width: 120 }}
              value={
                searchFilter ? searchFilter?.position : { id: "", value: "" }
              }
              getOptionLabel={(option) => option.value}
              onChange={(_, value) => handalSearchById("position", value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.value}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Position"
                  placeholder="Position"

                  InputProps={{
                    ...params.InputProps,
                    endAdornment: searchFilter?.position && searchFilter?.position?.value ? (
                      <InputAdornment position="end" style={{ position: "absolute", right: "0" }} >
                        <IconButton onClick={() => handalSearchById("position", { id: "", value: "" })}>
                          <CloseIcon style={{ fontSize: "18px" }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
            />
          ) : (
            <Skeleton variant="rounded" width={120} height={36} />
          )}
        </div>
        <div className="px-2 w-56">
          {!isSkeleton ? (
            <Autocomplete
              id="size-small-filled"
              size="small"
              options={punchTypeList}
              sx={{ width: 180 }}
              value={
                searchFilter?.punchtype
                  ? searchFilter?.punchtype
                  : { _id: "", value: "" }
              }
              getOptionLabel={(option) => option.value}
              onChange={(_, value) => handalSearchById("punchtype", value)}
              renderInput={(params) => (
                <TextField {...params} label="Punch Type"

                  InputProps={{
                    ...params.InputProps,
                    endAdornment: searchFilter?.punchtype && searchFilter?.punchtype?.value ? (
                      <InputAdornment position="end" style={{ position: "absolute", right: "0" }} >
                        <IconButton onClick={() => handalSearchById("punchtype", { id: "", value: "" })} >
                          <CloseIcon style={{ fontSize: "18px" }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }} />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ cursor: "pointer" }}
                  key={option._id}
                  className="py-1 ps-3 menu-drop-item"
                >
                  {" "}
                  {option.value}{" "}
                </li>
              )}
            />
          ) : (
            <Skeleton variant="rounded" width={120} height={36} />
          )}
        </div>
      </div>


      <SpecificFields
        isSpecificFieldsModal={isSpecificFieldsModal}
        setIsSpecificFieldsModal={setIsSpecificFieldsModal}
        handalFilter={handalFilter}
        searchFilter={searchFilter}
      />

    </div>
  );
};

export default ShiftFilter;
