import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import axios from "axios";
import { apis } from "../../apis";
import { Skeleton, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { get_facility } from "../../redux/slice/facility";
import MultipleSelect from "../input/MultipleSelect";
import { get_employee } from "../../redux/slice/employee";
import TableSection from "./table";
import EnhancedTable from "./EnhancedTable";
import "../../module_css/caregivertable.css"

const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'firstName', label: 'First Name', isSort: true },
    { id: 'lastName', label: 'Last Name', isSort: true },
    { id: 'phone', label: 'Phone', isSort: false },
    { id: 'facility', label: 'Facility', align: 'center', isSort: true },
    { id: 'action', label: 'Action', align: 'center', isSort: false },
];

export default function CaregiverTable({ }) {
    const [updateModel, setUpdateModel] = useState(false);
    const [loading, setLoading] = useState(false) // for CURD Operation
    const [isSkeleton, setIsSkeleton] = useState(true) // for fetching data
    const [FormData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const facility = useSelector(state => state.facility)
    const employees = useSelector((state) => state?.employees);
    const profile = useSelector(state => state.profile)
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    useEffect(() => {
        if (!facility?.status) {
            dispatch(get_facility({ token }))
        }
        if (!employees.status) {
            dispatch(get_employee({ token, user: profile?.data?._id }));
        }
        if (employees?.status) {
            setIsSkeleton(false)
        }
    }, [facility?.status, employees.status, profile?.status]);
    useEffect(() => {
        const dataRows = employees?.employee_data?.map((it, key) => {
            return {
                id: employees?.employee_data?.length - key,
                firstName: it?.firstName,
                lastName: it?.lastName,
                phone: it?.phone,
                facility: <div>
                    {it?.facility?.length > 0 ? it?.facility?.map((f) => (
                        <div>{f.name},</div>
                    )) : "N/A"}
                </div>,
                action: (
                    <>
                        <button className="btn" onClick={() => handleModel(it)}>
                            <TbEdit />
                        </button>
                        {/* <button className="btn" onClick={() => openDeleteModal(it)}>
                            <RiDeleteBin6Line />
                        </button> */}
                    </>
                ),
            }
        })
        setRows(dataRows?.reverse())
    }, [employees])


    const openDeleteModal = (it) => {
        setDeleteId(it);
        setConfirmDeleteModal(true);
    };

    const handalChange = ({ target }) => {
        const { name, value } = target;
        setFormData({
            ...FormData,
            [name]: value,
        });
    };
    const handleSelect = ({ name, value }) => {
        setFormData({
            ...FormData,
            [name]: value,
        });
    }

    function handleModel(item) {
        setFormData(item);
        setUpdateModel(true);
    };

    const validateForm = () => {
        let isValid = true;

        if (!FormData?.firstName?.trim()) {
            toast.error("First Name is required", { position: "top-right" });
            isValid = false;
        }

        if (!FormData?.lastName?.trim()) {
            toast.error("Last Name is required", { position: "top-right" });
            isValid = false;
        }

        if (!FormData?.phone?.trim()) {
            toast.error("Phone Number is required", { position: "top-right" });
            isValid = false;
        }

        return isValid;
    };


    const handleUpdate = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        try {
            const updata = await axios.post(
                apis.UPDATE_EMPLOYEE,
                {
                    _id: FormData._id,
                    phone: FormData?.phone,
                    firstName: FormData?.firstName,
                    lastName: FormData?.lastName,
                    facility: FormData?.facility,
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );
            setUpdateModel(false);
            toast.success("Caregiver Successfully updated.", { position: "top-right" });
            setFormData({});
            setTimeout(() => {
                dispatch(get_employee({ token, user: profile?.data?._id }));
            }, 1500);
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message, {
                position: "top-right",
            });
        } finally {
            setLoading(false);
        }
    };

// drop caregiver
    async function handalDrop() {
        try {
            const res = await axios.delete(`${apis.DROP_EMPLOYEE}/${deleteId?._id}`,
                {
                    headers: {
                        token: token,
                    },
                }
            );
            toast.success("Caregiver Successfully deleted..", {
                position: "top-right",
            });
            setConfirmDeleteModal(false);
            setTimeout(() => {
                dispatch(get_employee({ token, user: profile?.data?._id }));
            }, 200)
        } catch (error) {
            console.log(error)
        }
    }

    const filteredRows = [...rows]
        .filter(row => {
            const searchFields = [row.firstName, row.lastName, row.phone, row.facility];
            return searchFields.some(field => field?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
        })
        .map((row, index) => ({
            ...row,
            id: index + 1, // Start the ID from 1
        }));

    return (
        <>
            {/* Search Input */}
            {
                isSkeleton ? <div className="d-flex justify-content-end">
                    <Skeleton animation="wave" height={60} width={200} />
                </div> : <div className="container text-end mb-2 searchuser">
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => {
                            setPage(0)
                            setSearchQuery(e.target.value)
                        }}
                    />
                </div>
            }
            <EnhancedTable status={employees?.status} columns={columns} rows={filteredRows} page={page} setPage={setPage} />
            <Modal
                show={confirmDeleteModal}
                onHide={() => setConfirmDeleteModal(false)}
                centered
            >
                <Modal.Body>
                    <p className="d-flex justify-content-center" style={{ fontSize: "20px", fontWeight: "bold" }}>Are you sure you want to delete this caregiver?</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
                        Cancel
                    </Button>
                    {
                        loading ? <Button variant="success" type="button">Delete...</Button> : <Button variant="success" onClick={handalDrop}>Delete</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={updateModel}
                onHide={() => setUpdateModel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Caregiver
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row caregiveruser">
                        <div className="col-md-6 mb-4 text-center caregivertextfield">
                            <TextField
                                id="outlined-required"
                                fullWidth
                                label="First Name"
                                value={FormData?.firstName}
                                name="firstName"
                                onChange={handalChange}
                            />
                        </div>

                        <div className="col-md-6 mb-4 text-center caregivertextfield">
                            <TextField
                                id="outlined-required"
                                fullWidth
                                label="Last Name"
                                defaultValue={FormData?.lastName}
                                onChange={handalChange}
                                name="lastName"
                            />
                        </div>

                        <div className="col-md-6 mb-4 text-center mslect">
                            <MultipleSelect data={facility?.facility_data} value={FormData?.facility} name="facility" label="Facility" handleChange={handleSelect} />
                        </div>

                        <div className="col-md-6 mb-4 text-center caregivertextfield">
                            <TextField
                                id="outlined-required"
                                fullWidth
                                label="Phone"
                                defaultValue={FormData?.phone}
                                onChange={handalChange}
                                name="phone"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {
                            loading ? <Button className="bg-dark-pink" type="button">Loading...</Button> : <Button className="bg-dark-pink" onClick={handleUpdate}>Update Caregiver</Button>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}