import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineRestore } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import axios from "axios";
import { apis } from "../../apis";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Autocomplete, IconButton, InputAdornment, Skeleton, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import MultipleSelect from "../../components/input/MultipleSelect";
import SelectSingle from "../../components/input/SingleSelect";
import Cookies from "js-cookie";
import { updateUserState, get_user } from "../../redux/slice/user";
import { toast } from "react-toastify";
import { get_company } from "../../redux/slice/createcompany";
import { get_facility } from "../../redux/slice/facility";
import { get_roles } from "../../redux/slice/roles";
import TableSection from "./table";
import { get_agency } from "../../redux/slice/agency";
import { PiKeyBold } from "react-icons/pi";
import EnhancedTable from "./EnhancedTable";
import "../../module_css/userindex.css";
const columns = [
    { id: "id", label: "Sr No", isSort: true },
    { id: "name", label: "Name", isSort: true },
    { id: "company", label: "Company", isSort: true },
    { id: "roles", label: "Roles", isSort: true },
    { id: "email", label: "Email", align: "center", isSort: true },
    { id: "portal", label: "Agency Tracking", isSort: false },
    // { id: 'mail_report', label: 'Mail Report', align: 'end', isSort: false },
    {
        id: "subscribe",
        label: "Patient Communication",
        align: "end",
        isSort: false,
    },
    { id: "action", label: "Action", align: "center", isSort: false },
];

export default function UserTable() {
    const [Loading, setLoading] = useState(false);
    const [updateModel, setUpdateModel] = useState(false);
    const [updatePasswordModel, setUpdatPasswordeModel] = useState(false);
    const [FormData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const facility = useSelector((state) => state.facility);
    const user = useSelector((state) => state.user);
    console.log(user?.status,"user status ")

    const company = useSelector((state) => state.company);
    const roles = useSelector((state) => state.roles);
    const agency = useSelector((state) => state.agency);
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const [rows, setRows] = useState([]);
    const [newPassword, setNewPassword] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [isDeleted, setIsDeleted] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSkeleton, setIsSkeleton] = useState(true);
    const profile = useSelector((state) => state.profile);
    const [isToggleLoader, setIsToggleLoader] = useState({
        id: "",
        email: false,
        portal: false,
        subscribe: false,
    });
    const [validationErrors, setValidationErrors] = useState({
        company: "",
    });
    const [page, setPage] = useState(0);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

    const [restoreModal, setRestoreModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);


    useEffect(() => {
        if (!agency?.status && profile?.status) {
            dispatch(get_agency({ token, user: profile?.data?._id }));
        }
        if (!company?.status) {
            dispatch(get_company({ token }));
        }
        if (!facility?.status) {
            dispatch(get_facility({ token }));
        }
        if (!roles?.status) {
            dispatch(get_roles({ token }));
        }
    }, [token, profile]);

    useEffect(()=>{
        if (user?.status) {
            setIsSkeleton(false);
        }
        dispatch(get_user({ token , is_deleted:isDeleted }));
        setPage(0)
    },[isDeleted, token, profile])

    useEffect(() => {
        const dataRows = user?.user_data?.map((it, key) => {
            return {
                id: user?.user_data?.length - key,
                name: it?.first_name || it?.last_name ? `${it?.first_name} ${it?.last_name}` : "N/A",
                company: it?.company?.name ? it?.company?.name : "N/A",
                roles: it?.roles[0]?.name ? <span style={{ textTransform: "capitalize" }} >{it?.roles[0]?.name?.replace("_", " ")}</span> : "N/A",
                email: it?.email ? it?.email : "N/A",
                portal: (
                    <div className="form-check form-switch d-flex justify-content-center">
                        <input
                            style={{ cursor: "pointer" }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={() => handalPortalAccess(it)}
                            id="flexSwitchCheckDefault"
                            checked={it?.portal_access}
                        />
                    </div>
                ),
                // mail_report: (
                //     <div className="form-check form-switch d-flex justify-content-center">
                //         <input
                //             style={{ cursor: 'pointer' }}
                //             className="form-check-input"
                //             type="checkbox"
                //             role="switch"
                //             onChange={() => enableMailReportChange(it)}
                //             id="flexSwitchCheckDefault"
                //             checked={it?.enabled_mail_report}
                //         />
                //     </div>
                // ),
                subscribe: (
                    <div className="form-check form-switch d-flex justify-content-center">
                        <input
                            style={{ cursor: "pointer" }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={() => handalSubscribe(it)}
                            id="flexSwitchCheckDefault"
                            checked={it?.is_subscriptable_text}
                        />
                    </div>
                ),
                action: (
                    <>
                        <button className="btn" style={{ padding: "0.375rem" }} onClick={() => handleModel(it)} >
                            <TbEdit />
                        </button>
                        <button className="btn" style={{ padding: "0.375rem" }} onClick={() => handlePasswordModel(it)} >
                            <PiKeyBold />
                        </button>
                       { it?.is_deleted ? 
                        <button className="btn" style={{ padding: "0.375rem" }} onClick={() => openRestoreModal(it)}>
                            <MdOutlineRestore />
                        </button>
                        :
                        <button className="btn" style={{ padding: "0.375rem" }} onClick={() => openDeleteModal(it)}>
                            <RiDeleteBin6Line />
                        </button>
                        }
                    </>
                ),
            };
        });
        setRows(dataRows?.reverse() || []);
    }, [user?.user_data]);

    const openDeleteModal = (it) => {
        setDeleteId(it);
        setConfirmDeleteModal(true);
    };
    const openRestoreModal = (it) => {
        setDeleteId(it);
        setRestoreModal(true);
    };


    const handleChangeModal = ({ target }) => {
        const { name, value } = target;
        setFormData({
            ...FormData,
            // [name]: value,
            [name]: value === FormData[name] ? '' : value,
        });
    };
    const handleChangePasswordModal = ({ target }) => {
        const { name, value } = target;

        if (name === "newPassword") {
            setNewPassword(value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
    };

    // handal mail report
    async function enableMailReportChange(data) {
        setLoading(true);
        try {
            const res = await axios.post(
                apis.ENABLED_MAIL_REPORT,
                {
                    userid: data?._id,
                    enabled_mail_report: !data?.enabled_mail_report,
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );
            setLoading(false);
            setIsToggleLoader({
                id: data?._id,
                email: true,
                portal: false,
                subscribe: false,
            });
            dispatch(
                updateUserState({ _id: data?._id, field: "enabled_mail_report" })
            );
        } catch (error) {
            setLoading(false);
        }
    }

    // handal portal access
    async function handalPortalAccess(data) {
        setLoading(true);
        try {
            const res = await axios.put(
                apis.PORTAL_ACCESS,
                {
                    _id: data?._id,
                    portal_access: !data?.portal_access,
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );
            setLoading(false);
            setIsToggleLoader({
                id: data?._id,
                email: false,
                portal: true,
                subscribe: false,
            });
            dispatch(updateUserState({ _id: data?._id, field: "portal_access" }));
        } catch (error) {
            setLoading(false);
        }
    }

    // handal Subscribe
    async function handalSubscribe(data) {
        setLoading(true);
        try {
            const res = await axios.put(
                apis.SUBSCRIPTABLE_TEXT,
                {
                    _id: data?._id,
                    is_subscriptable_text: !data?.is_subscriptable_text,
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );
            setLoading(false);
            setIsToggleLoader({
                id: data?._id,
                email: false,
                portal: false,
                subscribe: true,
            });
            dispatch(
                updateUserState({ _id: data?._id, field: "is_subscriptable_text" })
            );
        } catch (error) {
            setLoading(false);
        }
    }

    function handleModel(item) {
        setFormData({
            ...item,
            company: item?.company?._id || "",
            roles: item.roles.length > 0 ? item.roles[0].name : "",
        });
        setUpdateModel(true);
    }

    function handlePasswordModel(it) {
        setUserEmail(it?.email)
        setUpdatPasswordeModel(true)
    }


    const handleSelect = (eventOrValue) => {
        let name, value;

        // Check if it's an event object (for regular inputs like Autocomplete)
        if (eventOrValue.target) {
            name = eventOrValue.target.name;
            value = eventOrValue.target.value;
        } else {
            // If it's coming from MultipleSelect, it will be an object
            name = eventOrValue.name;
            value = eventOrValue.value;
        }
        setFormData((prev) => {
            const updatedFormData = { ...prev, [name]: value };
            return updatedFormData;
        });
    };

    const handleUpdate = async () => {
        if (!FormData?.first_name) {
            return toast.error("First name is required.", { position: "top-right" });
        }
        if (!FormData?.last_name) {
            return toast.error("Last name is required.", { position: "top-right" });
        }
        if (!FormData?.email) {
            return toast.error("Email is required.", { position: "top-right" });
        }
        if (!FormData?.phone) {
            return toast.error("Phone is required.", { position: "top-right" });
        }
        if (FormData?.phone.length < 10) {
            return toast.error("Phone number must be at least 10 digits.", { position: "top-right" });
        }

        // Ensure FormData.roles is always a string for comparison
        const formattedRole =
            typeof FormData?.roles === "string"
                ? FormData?.roles.trim().toLowerCase()
                : "";

        // Find the role in the available roles list
        const selectedRole = roles?.roles?.find(role =>
            role.name.trim().toLowerCase() === formattedRole
        );

        // Get the role ID if found
        const roleId = selectedRole ? selectedRole._id : null;
        const isCompanyManager = !!selectedRole && selectedRole.name.toLowerCase() === "company_manager";

        if (isCompanyManager && (!FormData?.company || FormData?.company === "")) {
            return toast.error("Company is required when role is Company Manager.");
        }
        else {
            try {
                setLoading(true);
                const updatauser = await axios.put(
                    apis.UPDATE_USER,
                    {
                        Id: FormData?._id,
                        first_name: FormData?.first_name,
                        last_name: FormData?.last_name,
                        email: FormData?.email,
                        phone: FormData?.phone,
                        facility: FormData?.facility,
                        company: FormData?.company,
                        // roles: FormData?.roles,
                        roles: roleId ? [roleId] : [],
                        agency: FormData?.agency,
                    },
                    {
                        headers: {
                            token: token,
                        },
                    }
                );
                setUpdateModel(false);
                setLoading(false);
                setIsDeleted(false)
                toast.success("User Successfully updated..", {
                    position: "top-right",
                });
                setTimeout(() => {
                    dispatch(get_user({ token }));
                }, 100);
            } catch (error) {
                if (error?.response?.data?.msg) {
                    toast.error(error?.response?.data?.msg, {
                        position: "top-right",
                    });
                } else {
                    toast.error(error?.message, {
                        position: "top-right",
                    });
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // drop user
    async function handalDropUser() {
        try {
            const res = await axios.delete(`${apis.DROP_USER}/${deleteId?._id}`, {
                headers: {
                    token: token,
                },
            });
            toast.success("User successfully deleted..", {
                position: "top-right",
            });
            setConfirmDeleteModal(false);
            setTimeout(() => {
                dispatch(get_user({ token }));
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    // soft delete user
    async function handleDeactivateUser() {
        try {
            const res = await axios.put(`${apis.DEACTIVATE_USER}`, {
                userId: deleteId?._id,
                is_deleted: true,
            }, {
                headers: {
                    token: token,
                },
            });
            console.log("res", res);
            setConfirmDeleteModal(false)
            toast.success("User successfully deactivated..", {
                position: "top-right",
            });
            setTimeout(() => {
                dispatch(get_user({ token }));
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    // Restore user

    async function handleRestoreUser() {
        try {
            const res = await axios.put(`${apis.DEACTIVATE_USER}`, {
                userId: deleteId?._id,
                is_deleted: false,
            }, {
                headers: {
                    token: token,
                },
            });
            console.log("res", res);
            toast.success("User successfully restore..", {
                position: "top-right",
            });
            setRestoreModal(false)
            setIsDeleted(false)
            setTimeout(() => {
                dispatch(get_user({ token }));
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdatePassword = async () => {
        if (!newPassword) {
            return toast.error("New password is required.", { position: "top-right" });
        }
        if (!confirmPassword) {
            return toast.error("Confirm password is required.", { position: "top-right" });
        }
        if (newPassword !== confirmPassword) {
            return toast.error("Passwords do not match.", { position: "top-right" });
        }

        try {
            setLoading(true);
            await axios.put(
                apis.COMPANY_MANAGER_UPDATE_USER_PASSWORD,
                {
                    userEmail: userEmail,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword
                },
                {
                    headers: { token: token },
                }
            );
            setUpdatPasswordeModel(false);
            setNewPassword('')
            setConfirmPassword('')
            toast.success("Password updated successfully.", { position: "top-right" });
        } catch (error) {
            toast.error(error?.response?.data?.msg || error?.message, { position: "top-right" });
        } finally {
            setLoading(false);
        }
    };

    const filteredRows = [...rows]
        .filter((row) => {
            const searchFields = [row.name, row.company, row.roles, row.email];
            return searchFields.some((field) =>
                field?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            );
        })
        .map((row, index) => ({
            ...row,
            id: index + 1,
        }));

    return (
        <>
            {/* {isSkeleton ? (
                <div className="d-flex justify-content-end">
                    <Skeleton animation="wave" height={60} width={200} />
                </div>
            ) : ( */}
            <div className="tablehead_wrapper container" >
                <div className="tab-container mb-2">
                    <button
                        className={`tab-button ${isDeleted ? '' : 'tab-active'}`}
                        onClick={() => setIsDeleted(false)}
                    >
                        Activated
                    </button>
                    <button
                        className={`tab-button ${isDeleted ? 'tab-active' : ''}`}
                        onClick={() => setIsDeleted(true)}
                    >
                        Deactivated
                    </button>
                </div>
                <div className="text-end mb-2 searchuser">
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => {
                            setPage(0);
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>
            </div>
            {/* )} */}
            <EnhancedTable
                status={user?.status}
                columns={columns}
                rows={filteredRows}
                page={page}
                setPage={setPage}
            />

            <Modal
                show={confirmDeleteModal}
                onHide={() => setConfirmDeleteModal(false)}
                centered
            >
                <Modal.Body>
                    <p className="d-flex justify-content-center" style={{ fontSize: "20px", fontWeight: "bold" }}>Are you sure you want to deactivate this user?</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button className="bg-dark-pink"  onClick={handleDeactivateUser}>Delete</Button>
                </Modal.Footer>
            </Modal>
{/* Restore modal  */}
            <Modal
                show={restoreModal}
                onHide={() => setRestoreModal(false)}
                centered
            >
                <Modal.Body>
                    <p className="d-flex justify-content-center" style={{ fontSize: "20px", fontWeight: "bold" }}>Are you sure you want to restore this user?</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() =>  setRestoreModal(false)}>
                        Cancel
                    </Button>
                    <Button className="bg-dark-pink" onClick={handleRestoreUser}>Restore</Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={updateModel}
                onHide={() => setUpdateModel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row userdrow">
                        <div className="col-md-6 mb-6 text-center ttfield usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="First Name"
                                value={FormData?.first_name}
                                onChange={handleChangeModal}
                                name="first_name"
                            />
                        </div>
                        <div className="col-md-6 mb-6 text-center usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Last Name"
                                defaultValue={FormData?.last_name || "N/A"}
                                onChange={handleChangeModal}
                                name="last_name"
                            />
                        </div>
                    </div>
                    <div className="row mt-4 rowalign">
                        <div className="col-md-6 mb-6 text-center usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Phone"
                                defaultValue={FormData?.phone || "N/A"}
                                onChange={handleChangeModal}
                                name="phone"
                            />
                        </div>
                        <div className="col-md-6 mb-6 text-center ttfield usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Email"
                                defaultValue={FormData?.email || "N/A"}
                                onChange={handleChangeModal}
                                name="email"
                            />
                        </div>
                    </div>

                    <div className="row mt-4 rowalign2">
                        <div className="col-md-6 mb-6 text-center sssingal">
                            <Autocomplete
                                options={roles?.roles?.map(role => ({ ...role, label: role?.name?.replace("_", " ")?.replace(/\b\w/g, char => char.toUpperCase()) })) || []}
                                getOptionLabel={(option) => (option.label || option.name?.replace("_", " "))?.replace(/\b\w/g, char => char.toUpperCase()) || ""}
                                value={
                                    roles?.roles?.find((role) => role.name === FormData?.roles) || null
                                }
                                onChange={(event, newValue) => {
                                    handleSelect({
                                        target: { name: "roles", value: newValue ? newValue?.name : " " },
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} label="Roles" name="roles" variant="outlined" />}
                            />
                        </div>

                        <div className="col-md-6 mb-6 text-center sssingal ttfield ">
                            <MultipleSelect
                                data={agency?.agency}
                                value={FormData?.agency || "N/A"}
                                name="agency"
                                label="Agency"
                                handleChange={handleSelect}
                            />
                        </div>
                    </div>
                    <div className="row mt-4 rowalign4">
                        <div className="col-md-6 mb-6 text-center sssingal ">
                            <Autocomplete
                                options={company?.company_data || []}
                                getOptionLabel={(option) => option.name || ""}
                                value={
                                    company?.company_data?.find(
                                        (item) => item._id === (typeof FormData?.company === "string" ? FormData?.company : FormData?.company?._id)
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    handleChangeModal({
                                        target: { name: "company", value: newValue ? newValue._id : "" },
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} label="Company" name="company" variant="outlined" />}
                            />
                        </div>
                        <div className="col-md-6 mb-6 text-center sssingal ttfield">
                            <MultipleSelect
                                data={
                                    FormData?.company
                                        ? facility?.facility_data?.filter(fac => fac?.company?._id ===
                                            (typeof FormData?.company === "string" ? FormData?.company : FormData?.company?._id)
                                        ) || []
                                        : facility?.facility_data || []
                                }

                                value={FormData?.facility || "N/A"}
                                name="facility"
                                label="Facility"
                                handleChange={handleSelect}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {Loading ? (
                            <button className="btn btn-success bg-dark-pink" type="button">
                                Loading...
                            </button>
                        ) : (
                            <button className="btn btn-success bg-dark-pink" onClick={handleUpdate}>
                                Update User
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={updatePasswordModel}
                onHide={() => setUpdatPasswordeModel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row userdrow">
                        <div className="col-md-6 mb-6 text-center ttfield usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="New Password"
                                value={newPassword}
                                onChange={handleChangePasswordModal}
                                name="newPassword"
                                type={showNewPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-6 text-center usertextfield">
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Confirm Password"
                                value={confirmPassword}
                                onChange={handleChangePasswordModal}
                                name="confirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {Loading ? (
                            <button className="btn btn-success bg-dark-pink" type="button">
                                Loading...
                            </button>
                        ) : (
                            <button className="btn btn-success bg-dark-pink" onClick={handleUpdatePassword}>
                                Update password
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}