import React, { useEffect, useState } from 'react'
import Navbar2 from '../NavBar2'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress, Skeleton, Tooltip, IconButton } from '@mui/material'
import { Modal } from "react-bootstrap";
import DynamicTable from '../../components/table/dynamicTable'
import moment from 'moment'
import WebHookFilter from './filter'
import { IoIosGitPullRequest } from "react-icons/io";
import { LuFileJson } from "react-icons/lu";
import { BsMeta } from "react-icons/bs";
import { MdPunchClock } from 'react-icons/md'
import { toast } from 'react-toastify'
const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'agency_name', label: 'Agency Name', isSort: true },
    { id: 'sent_at', label: 'Sent At', isSort: true },
    { id: 'punch', label: 'Punch', isSort: true },
    { id: 'response_status_code', label: 'Response Status Code', isSort: true },
    { id: 'full_request', label: 'Request', isSort: true },
    { id: 'response', label: 'Response', isSort: true },
    { id: 'meta', label: 'Meta', isSort: true },
];
const WebHook = () => {
    const profile = useSelector(state => state.profile)
    const [rows, setRows] = useState([])
    const webHookLogsData = useSelector(state => state?.webhook)
    const [hideColumn, setHideColumn] = useState(false);
    const [filterColumn, setFilterColumn] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isSkeleton, setIsSkeleton] = useState(true)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [open, setOpen] = useState(false);
    const [isInfoData, setIsInfoData] = useState();
    const [isInfoHeading, setIsInfoHeading] = useState();
    useEffect(() => {
        if (webHookLogsData?.status && !webHookLogsData?.loading) {
            setIsSkeleton(false)
        }
        const dataRows = webHookLogsData?.data?.data?.map((it, key) => {
            // Format the date and time using toLocaleString
            return {
                id: key + 1,
                agency_name: it?.agencyName || "N/A",
                sent_at: it?.sent_at ? moment(it?.sent_at).utc().format("MM/DD/YYYY hh:mm A") : "N/A" || 'N/A',
                punch: (
                    <Tooltip title={it?.punch_string}>
                        <IconButton>
                            <MdPunchClock size={18} />
                        </IconButton>
                    </Tooltip>
                ),
                response_status_code: it?.response_status_code || "N/A",
                full_request: (
                    <div className="flex items-center justify-center h-screen">
                        <IoIosGitPullRequest
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.full_request), setIsInfoHeading("Full Request Details") }}
                        />
                    </div>
                ),
                response: (
                    <div className="flex items-center justify-center h-screen">
                        <LuFileJson
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.response); setIsInfoHeading("Respose Details") }}
                        />
                    </div>
                ),
                meta: (
                    <div className="flex items-center justify-center h-screen">
                        <BsMeta
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.meta), setIsInfoHeading("Meta Details") }}
                        />
                    </div>
                ),
                punch_date: it?.request_data?.datetime ? moment(it?.request_data?.datetime).utc().format("MM/DD/YYYY hh:mm A") : "N/A",
            }
        })
        setRows(dataRows)
    }, [webHookLogsData])

    useEffect(() => {
        let savedColumns = JSON.parse(localStorage.getItem("hidenColumns")) || [];

        const matchedColumnIds = columns
            ?.map(col => col.id)
            .filter(id => savedColumns.includes(id));

        // Set matched column IDs
        setSelectedColumns(matchedColumnIds);
    }, [columns, filterColumn]);
    // useEffect(() => {
    //     const savedColumns = JSON.parse(localStorage.getItem("hidenColumns")) || [];
    //     setSelectedColumns(savedColumns);

    // }, [filterColumn]);


    const handleColumnToggle = (columnId) => {
        setSelectedColumns((prev) =>
            prev.includes(columnId)
                ? prev.filter((id) => id !== columnId) // Remove if already selected
                : [...prev, columnId] // Add if not selected
        );
    };
    const handleApply = () => {
        const totalColumns = columns?.length;
        const maxSelectable = totalColumns - 2;
        if (selectedColumns?.length > maxSelectable) {
            toast.error("You must keep at least 2 columns visible!", {
                position: "top-right",
            });
            return;
        }
        localStorage.setItem("hidenColumns", JSON.stringify(selectedColumns));
        setHideColumn(false)
    };
    return (
        <>
            <Navbar2 />
            {
                webHookLogsData?.loading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <div className="container py-4">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4 d-flex align-items-center">
                        <NavLink to={"/landing-page"} className="text-dark">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </NavLink>
                        <p className="admin-name-choose-sel rounded mb-0 mx-2 py-3 px-4">
                            Webhook logs
                        </p>
                    </div>
                    <div className="col-8 d-flex justify-content-end">
                        <div>
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/dashboard" className={"admin-name-choose-sel text-decoration-none py-3 mx-2 px-4 rounded"}>
                                    Shifts
                                </NavLink>
                            )}
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/admin" className={"admin-name-choose-sel text-decoration-none py-3 px-4 rounded"}>
                                    Admin Panel
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="dash-main py-3">
                <div className="container shift-filter">
                    <WebHookFilter isSkeleton={isSkeleton} setIsSkeleton={setIsSkeleton} setPage={setPage} page={page} limit={rowsPerPage} setHideColumn={setHideColumn} setFilterColumn={setFilterColumn} />
                </div>
                <div className="container py-3">
                    {
                        !isSkeleton ?
                            <DynamicTable status={webHookLogsData?.status} columns={columns} rows={rows} page={page} totalPage={webHookLogsData?.data?.totalRecords} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} setFilterColumn={setFilterColumn} />
                            :
                            <div>
                                <div className="py-2">
                                    <Skeleton variant="rounded" height={36} />
                                </div>
                                <div className="d-flex justify-content-between  py-2">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Modal show={open} onHide={() => setOpen(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {isInfoHeading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre className="text-sm font-mono">{JSON.stringify(isInfoData, null, 2)}</pre>
                </Modal.Body>
            </Modal>

            <Modal
                show={hideColumn}
                onHide={() => {
                    setHideColumn(false)
                    setFilterColumn(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Hide Coulmns
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="p-2">

                            <div className="row ">
                                {columns.map((col) => (
                                    <div key={col.id} className="col-md-4 mb-3">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={col.id}
                                                checked={selectedColumns.includes(col.id)}
                                                onChange={() => handleColumnToggle(col.id)}
                                            />
                                            <label className="form-check-label" htmlFor={col.id}>
                                                {col.label}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <button className="btn btn-success bg-dark-pink" onClick={handleApply}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default WebHook