import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import {apis} from "../../apis"



export const get_Company_UserData = createAsyncThunk(
    "getcompanyuserdata",
    async ({ token, company_id }, { rejectWithValue }) => {
      try {
        const getData = await axios.get(`${apis.GET_COMPANY_USER_DATA}/${company_id}`
, {
          params: { companyId: company_id },
          headers: { token }
        });
        return getData.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
  

const getCompanyUserData = createSlice({
    name:"get_Company_UserData",
    initialState:{
        status:false,
        loading:false,
        error:'',
        company_user_data:[]
    },
    extraReducers:(builder)=>{
        builder.addCase(get_Company_UserData.pending,(state)=>{
            state.loading = true
        })
        builder.addCase(get_Company_UserData.fulfilled,(state,action)=>{
            state.company_user_data = action.payload;
            state.status=true;
            state.loading = false;
            state.error = '';
        })
        builder.addCase(get_Company_UserData.rejected,(state,action)=>{
            state.error = action.payload;
            state.loading = false;
            state.device_data = []; 
        })
    }
})

export default getCompanyUserData.reducer