import React from "react";
import NavBar2 from "../NavBar2";
import Adminaside from "../../components/asides/Adminaside";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { admin_dashboard_page } from "../../static-data/admin_dashboard";
import "../../module_css/admin_page.css"

const Admin = () => {
  return (
    <>
      <div className="admin-dashboard">
        <div className="admin-nav">
          <NavBar2 />
        </div>
        <div className="admin-container ">
          <div className="aside text-center align-item-center">
            <Adminaside />
          </div>
            <div className="admin-inner p-4">
              <>
                <Row className="justify-content-evenly admin-card-box">
                  {
                    admin_dashboard_page?.map((it, keys) => {
                      return (
                        <Col key={keys + "1"} className="py-2" xl={3} lg={3} md={4} sm={6} xm={12}>
                          <NavLink to={it?.path} style={{ color: "Black", textDecoration: "None" }} >
                            <div style={{ cursor: "pointer" }} className="admin-card" >
                              <div className="d-flex ">
                                <div className="py-4 d-flex align-items-center justify-content-center admin-icon">
                                  {it?.icon}
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center py-3">
                                  <h3 className="text-center">{it?.title}</h3>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </Col>
                      )
                    })
                  }
                </Row>
              </>
            </div>
        </div>
      </div>
    </>
  );
};
export default Admin;
