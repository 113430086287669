import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { apis } from "../../apis";
import { get_facility } from "../../redux/slice/facility";
import { get_device } from "../../redux/slice/device";
import EnhancedTable from "./EnhancedTable";
import "../../module_css/caregivertable.css";
import { TbEdit } from "react-icons/tb";
import { LuTrash2 } from "react-icons/lu";
import { get_client } from "../../redux/slice/client";
import { get_user } from "../../redux/slice/user";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa";

const columns = [
  { id: "id", label: "Sr No", isSort: true },
  { id: "name", label: "Name", isSort: true },
  { id: "clientId", label: "Client ID", isSort: true },
  { id: "clientSecret", label: "Client Secret", align: "center", isSort: true },
  { id: "action", label: "Action", align: "center", isSort: false },
];

export default function ClientTable() {
  const [updateModel, setUpdateModel] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [formData, setFormData] = useState({});


  const [deleteId, setDeleteId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const facility = useSelector((state) => state.facility);
  const device = useSelector((state) => state.device.device_data) || [];
  const client = useSelector((state) => state.client.client_data) || [];

  const clientstatus = useSelector((state) => state.client) || [];
  const user = useSelector((state) => state.user);


  function truncateText(text, length) {
    if (text?.length > length) {
      return `${text.substring(0, length)}...`;
    }
    return text;
  }

  useEffect(() => {
    if (!facility?.status) dispatch(get_facility({ token }));
    if (!user?.status) dispatch(get_user({ token }));
    if (!client?.status) dispatch(get_client({ token }));

  }, [facility?.status, client?.status]);




  useEffect(() => {
    if (client && client?.length > 0) {
      const dataRows = client?.map((it, index) => ({
        id: index + 1,
        name: `${it?.userId?.first_name} ${it?.userId?.last_name}`,
        clientId: it?.clientId,
        clientSecret: it?.clientSecret,
        clientSecret: (
          <Tooltip
            title={it?.clientSecret}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: "1rem",
                  padding: "5px",
                  backgroundColor: "gray",
                  color: "#fff",
                  maxWidth: "450px",
                  wordWrap: "break-word",
                },
              },
            }}
          >
            <span className="cursor-pointer">
              {truncateText(it?.clientSecret || "N/A", 20)}
            </span>
          </Tooltip>
        ),
        action: (
          <>
            <button className="btn" onClick={() => handleModel(it)}>

              <Tooltip
                title="Details"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "1rem",
                      padding: "5px",
                      backgroundColor: "gray",
                      color: "#fff",
                      maxWidth: "450px",
                      wordWrap: "break-word",
                    },
                  },
                }}
              >
                <span className="cursor-pointer">
                  <FaCircleExclamation />
                </span>
              </Tooltip>
            </button>
            <button className="btn" onClick={() => openDeleteModal(it?.clientId)}>
              <LuTrash2 />
            </button>
          </>
        ),
      }));

      setRows(dataRows);
      setIsSkeleton(false);
    } else {
      setRows([]);
      setIsSkeleton(true);
    }
  }, [client]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    return true;
  };

  const openDeleteModal = (it) => {
    setDeleteId(it);
    setConfirmDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!deleteId) return;

    setLoading(true);
    try {
      await axios.delete(`${apis.DELETE_CLIENT_CREDENTIAL}/${deleteId}`, {
        headers: { token: token },
      });
      toast.success("Client successfully deleted.", { position: "top-right" });
      setConfirmDeleteModal(false);
      dispatch(get_client({ token }));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  function handleModel(item) {
    setFormData(item);
    setUpdateModel(true);
  }
  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const update = await axios.put(
        `${apis.UPDATE_CLIENT_CREDENTIAL}/${formData.clientId}`,
        {
          id: formData._id,
          userId: formData?.userId?._id,
          secret: formData?.clientSecret,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
        },
        {
          headers: {
            token: token,
          },
        }
      );

      setUpdateModel(false);
      toast.success("Client successfully updated.", { position: "top-right" });
      setFormData({});
      dispatch(get_client({ token }));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };


  const handleSelect = (selectedUser) => {
    if (selectedUser) {
      setFormData({
        ...formData,
        firstName: selectedUser.first_name,
        lastName: selectedUser.last_name,
      });
    } else {
      setFormData({
        ...formData,
        firstName: "",
        lastName: "",
      });
    }
  };

  const filteredRows = rows.filter((row) =>
    [row.name].some((field) =>
      field?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <div className="container text-end mb-2">
        <TextField
          label="Search"
          type="search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            setPage(0);
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <EnhancedTable
        status={clientstatus?.status}
        columns={columns}
        rows={filteredRows}
        page={page}
        setPage={setPage}
      />
      <Modal
        show={confirmDeleteModal}
        onHide={() => setConfirmDeleteModal(false)}
        centered
      >
        <Modal.Body>
          <p className="d-flex justify-content-center" style={{ fontSize: "20px", fontWeight: "bold" }}>Are you sure you want to delete this device?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
            Cancel
          </Button>
          <Button className="bg-dark-pink" onClick={handleDelete} disabled={loading}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={updateModel}
        onHide={() => setUpdateModel(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-11 text-center">
              <TextField
                fullWidth
                label="Client Id"
                name="clientId"
                value={formData?.clientId || ""}
                onChange={handleChange}
                required
                sx={{
                  pointerEvents: "none",
                }}
              />
            </div>
              <button
                className="col-sm-1"
                style={{
                  background:"transparent"
                }}
                onClick={() => {
                  navigator.clipboard.writeText(formData?.clientId || "");
                  toast.success("Client Id copied")
                }}
              >
                <FaCopy size={30} />
              </button>
          </div>
          <br></br>
          <div className="row">
            <div className="col-sm-11 text-center">
              <TextField
                fullWidth
                label="Secret"
                name="clientSecret"
                value={formData?.clientSecret || ""}
                onChange={handleChange}
                required
                sx={{
                  pointerEvents: "none",
                }}
              />
            </div>
            <button
                className="col-sm-1"
                style={{
                  background:"transparent"
                }}
                onClick={() => {
                  navigator.clipboard.writeText(formData?.clientSecret || "");
                  toast.success("Client Secret copied")
                }}
              >
                <FaCopy size={30} />
              </button>
          </div>
          {/* <TextField fullWidth label="Facility" name="device_id" value={formData?.facility[0]?.name || ''} onChange={handleChange} className="mt-3" /> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
