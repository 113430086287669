import React, { useState, useEffect } from "react";
import NavBar2 from "../NavBar2";
import { apis } from "../../apis";
import axios from "axios";
import Cookies from "js-cookie";
import Adminaside from "../../components/asides/Adminaside";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_company } from "../../redux/slice/createcompany";
import AdminHeader from "../../components/comman/admin_header";
import { Col, Row } from "react-bootstrap";
import { get_twilio } from "../../redux/slice/createtwilio";
import "../../module_css/createtwiliocreds.css";
import MultipleSelect from "../../components/input/MultipleSelect";
import { get_facility } from "../../redux/slice/facility";
import { get_device } from "../../redux/slice/device";

export default function CreateDevices() {
  let token = Cookies.get("token");
  const navigate = useNavigate();

  const formInit = {
    device_id: "",
    facility: "",
    device_name: ""
  };
  const [formData, setFormData] = useState(formInit);
  const facility = useSelector((state) => state.facility);
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState("");
  const [error, setError] = useState(formData);
  const [successToast, setSuccessToast] = useState(false);
  const [errToast, setErrToast] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!facility?.status && !facility?.loading)
      dispatch(get_facility({ token }));
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(!formData.device_name){
      toast.error("Device name is required.");
      return;
    }
    if(!formData.device_id){
      toast.error("Device id is required.");
      return;
    }
    if(!formData.facility){
      toast.error("Facility is required.");
      return;
    }

    setLoading(true);
    setResError("");

    let errorList = {};

    if (Object.keys(errorList).length > 0) {
      setError(errorList);
      setErrToast(true);
      setLoading(false);
      return;
    }

    try {
      const headers = { token };
      const res = await axios.post(apis.CREATE_DEVICES, formData, { headers });
      if (res.status === 200 || res.status === 201) {
        toast.success("Device created successfully");
        navigate("/admin/device/dashboard");
        setFormData(formInit);
        dispatch(get_device({ token }));
      }
    } catch (error) {
      setResError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="admin-dashboard">
        <div className="admin-nav">
          <NavBar2 />
        </div>
        <div className="admin-container">
          <div className="aside text-center align-item-center">
            <Adminaside />
          </div>
          <AdminHeader
            backTitle="Dashboard"
            backPath="/admin"
            action_path="/admin/device/dashboard"
            action="All Device"
          />
          <div
            className="create-user twiliouser
        "
          >
            <form onSubmit={handleSubmit}>
              <h1 className="text-center mb-3">Create Device</h1>
              <div className="container">
              <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="py-2"
                  >
                    <TextField
                      label="Device Name"
                      value={formData?.device_name}
                      onChange={handleInputChange}
                      name="device_name"
                      fullWidth
                      color={formData.device_name ? "success" : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="py-2"
                  >
                    <TextField
                      label="Device Id"
                      value={formData?.device_id}
                      onChange={handleInputChange}
                      name="device_id"
                      fullWidth
                      color={formData.device_id ? "success" : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="py-2"
                  >
                    <Autocomplete
                      options={
                        Array.isArray(facility?.facility_data)
                          ? [...facility?.facility_data].sort((a, b) => a?.name?.localeCompare(b?.name))
                          : []
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      value={formData?.facility || null}
                      onChange={(event, newValue) => {
                        handleSelect({ name: "facility", value: newValue || null });
                      }}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => <TextField {...params} label="Facility" variant="outlined" />}
                    />
                  </Col>
                </Row>
                <div className="mt-2 d-flex justify-content-center">
                  <button
                    type={loading ? "button" : "submit"}
                    className="btn btn-success bg-dark-pink"
                  >
                    {loading ? "Loading..." : "Create Device"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
