import React, { useEffect, useState } from 'react'
import Navbar2 from '../NavBar2'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import ShiftFilter from '../../components/partial-kiosk-punches/filter'
import EnhancedTable from '../../components/table/EnhancedTable'
import { Backdrop, CircularProgress, TextField, Skeleton } from '@mui/material'
import { FaCircleExclamation } from "react-icons/fa6";
import { Modal } from "react-bootstrap";
import axios from 'axios'
import { apis } from '../../apis'
import { get_partial_kiosk_punches } from '../../redux/slice/partial-kiosk-punches'
import { updateFilter } from '../../redux/slice/partial-kiosk-punches'
import DynamicTable from '../../components/table/dynamicTable'
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import { toast } from 'react-toastify'


const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'location_id', label: 'Location Id', isSort: true },
    { id: 'message', label: 'Error Messages', isSort: true },
    { id: 'caregiver_name', label: 'Employee Name', isSort: true },
    { id: 'agency_name', label: 'Agency Name', align: 'center', isSort: true },
    { id: 'facility_name', label: 'Facility Name', align: 'center', isSort: true },
    { id: 'punch_type', label: 'Punch Type', isSort: true },
    { id: 'facility_mapping_id', label: 'Facility Mapping Id', isSort: true },
    { id: 'punch_date', label: 'Punch Date', isSort: true },
    { id: 'details', label: 'Details', isSort: false },
];

const PartialKioskPunches = () => {



    const profile = useSelector(state => state.profile)
    const facility = useSelector(state => state?.facility)
    const [rows, setRows] = useState([])
    const partial_punch = useSelector(state => state?.partial_kiosk_punches)
    const [isSkeleton, setIsSkeleton] = useState(true)
    const [page, setPage] = useState(0)
    const dispatch = useDispatch()
    const token = Cookies.get('token')
    const [modalShow, setModalShow] = useState(false)
    const [hideColumn, setHideColumn] = useState(false);
    const [filterColumn, setFilterColumn] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [modalData, setModalData] = useState({})
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [facilityList, setFacilityList] = useState([])

    useEffect(() => {
        if (!partial_punch?.status && !partial_punch?.loading) {
            dispatch(get_partial_kiosk_punches({ token, limit: rowsPerPage, page: page  }));
        }
        if (partial_punch?.status && !partial_punch?.loading) {
            setIsSkeleton(false);
        }
        const dataRows = partial_punch?.data?.map((it, key) => {
            let punch_string = it?.request_data?.punch_string;

            if (typeof punch_string === "string") {
                try {
                    let parsed = JSON.parse(punch_string);
                    if (typeof parsed === "object" && parsed !== null) {
                        punch_string = parsed;
                    } else {
                        punch_string = punch_string.split("-");
                    }
                } catch (error) {
                    punch_string = punch_string.split("-");
                }
            } else if (typeof punch_string === "object" && punch_string !== null) {
                punch_string = [punch_string];
            }
            const date = new Date(it?.request_data?.datetime);
            const formattedDate = date.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true
            }).replace(",", "-");
            return {
                id: key + 1,
                caregiver_name: it?.meta_data?.caregiver_name || "N/A",
                punch_type: it?.type || "N/A",
                location_id: it?.request_data?.location_id || "N/A",
                message: (
                    <Tooltip
                        title={it?.request_data?.message}
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: "1rem",
                                    padding: "5px",
                                    backgroundColor: "gray",
                                    color: "#fff",
                                    maxWidth: "450px",
                                    wordWrap: "break-word",
                                },
                            },
                        }}
                    >
                        <span className="cursor-pointer">
                            {truncateText(it?.request_data?.message || "N/A", 20)}
                        </span>
                    </Tooltip>
                ),
                agency_name: it?.meta_data?.agency_name || "N/A",
                facility_name: it?.meta_data?.facility_name || "N/A",
                facility_mapping_id: it?.meta_data?.facility_mapping_id || "N/A",
                punch_date: it?.request_data?.datetime
                    ? moment(it?.request_data?.datetime).utc().format("MM/DD/YYYY hh:mm A")
                    : "N/A",
                details: (
                    <button className="btn" onClick={() => handleShowModal(it)}>
                        <FaCircleExclamation />
                    </button>
                ),
            };
        });
        setRows(dataRows);
    }, [partial_punch]);

    useEffect(() => {
        setFacilityList(facility?.facility_data)
    }, [facility, profile])


    function truncateText(text, length) {
        if (text?.length > length) {
            return `${text.substring(0, length)}...`;
        }
        return text;
    }


    async function handleShowModal(payload) {
        let punch_string = payload?.request_data?.punch_string


        if (typeof punch_string === "string") {
            try {
                // First, remove escape characters
                let cleanedString = punch_string.replace(/\\"/g, '"'); // Unescape quotes

                // Then, parse into an object
                punch_string = JSON.parse(cleanedString);
            } catch (error) {
                console.error("Error parsing punch_string:", error);
            }
        }

        const date = new Date(payload?.request_data?.datetime);

        // Format the date and time using toLocaleString
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const formattedDate = date.toLocaleString('en-GB', options).replace(',', '-');
        const data = {
            caregiver_name: payload?.meta_data?.caregiver_name || "",
            punch_type: payload?.type || '',
            is_success: payload?.is_success ? 'Success' : 'Failed' || '',
            punch_string: typeof punch_string === "object" ? JSON.stringify(punch_string, null, 2) : punch_string,
            location_id: payload?.request_data?.location_id || "",
            message: payload?.message?.message || "",
            agency_name: payload?.meta_data?.agency_name || "",
            facility_name: payload?.meta_data?.facility_name || "",
            facility_mapping_id: payload?.meta_data?.facility_mapping_id || "",
            // punch_date: formattedDate || "",
            punch_date: payload?.request_data?.datetime ? moment(payload?.request_data?.datetime).utc().format("MM/DD/YYYY hh:mm A") : "N/A",
            message: payload?.request_data?.message || ""

        }
        setModalData(data)
        setModalShow(true)
    }

    useEffect(() => {
        let savedColumns = JSON.parse(localStorage.getItem("hidenColumns")) || [];

        const matchedColumnIds = columns
            ?.map(col => col.id)
            .filter(id => savedColumns.includes(id));

        // Set matched column IDs
        setSelectedColumns(matchedColumnIds);
    }, [columns, filterColumn]);

    // useEffect(() => {
    //     const savedColumns = JSON.parse(localStorage.getItem("hidenColumns")) || [];
    //     setSelectedColumns(savedColumns);

    // }, [filterColumn]);


    const handleColumnToggle = (columnId) => {
        setSelectedColumns((prev) =>
            prev.includes(columnId)
                ? prev.filter((id) => id !== columnId)
                : [...prev, columnId]
        );
    };
    const handleApply = () => {
        const totalColumns = columns?.length;
        const maxSelectable = totalColumns - 2;
        if (selectedColumns?.length > maxSelectable) {
            toast.error("You must keep at least 2 columns visible!", {
                position: "top-right",
            });
            return;
        }
        localStorage.setItem("hidenColumns", JSON.stringify(selectedColumns));
        setHideColumn(false)
    };

    return (
        <>
            <Navbar2 />
            {
                partial_punch?.loading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            <div className="container py-4">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4 d-flex align-items-center">
                        <NavLink to={"/landing-page"} className="text-dark">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </NavLink>
                        <p className="admin-name-choose-sel rounded mb-0 mx-2 py-3 px-4">
                            Partial Kiosk Punches
                        </p>
                    </div>
                    <div className="col-8 d-flex justify-content-end">
                        <div>
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/dashboard" className={"admin-name-choose-sel text-decoration-none py-3 mx-2 px-4 rounded"}>
                                    Shifts
                                </NavLink>
                            )}
                            <NavLink to="/webhook-logs" className={"admin-name-choose-sel text-decoration-none py-3 mx-2 px-4 rounded"}>Webhook logs</NavLink>
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/admin" className={"admin-name-choose-sel text-decoration-none py-3 px-4 rounded"}>
                                    Admin Panel
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="dash-main py-3">
                <div className="container shift-filter">
                    <ShiftFilter isSkeleton={isSkeleton} setIsSkeleton={setIsSkeleton} setPage={setPage} page={page} limit={rowsPerPage} setHideColumn={setHideColumn} setFilterColumn={setFilterColumn} />
                </div>
                <div className="container py-3">
                    {
                        !isSkeleton ? <DynamicTable status={partial_punch?.status} columns={columns} rows={rows} page={page} totalPage={partial_punch?.total} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} setFilterColumn={setFilterColumn} /> :
                            <div>
                                <div className="py-2">
                                    <Skeleton variant="rounded" height={36} />
                                </div>
                                <div className="d-flex justify-content-between  py-2">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Partial Punche Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row">
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Employee Name"
                                    value={modalData?.caregiver_name || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={
                                        { pointerEvents: "none" }
                                    }
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Agency Name"
                                    value={modalData?.agency_name || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Facility Name"
                                    value={modalData?.facility_name || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Facility Mapping Id"
                                    value={modalData?.facility_mapping_id || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Punch Date"
                                    value={modalData?.punch_date || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-6 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Punch Type"
                                    value={modalData?.punch_type || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-12 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Message"
                                    value={modalData?.message || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>
                            <div className="col-md-12 mb-4 text-center">
                                <TextField
                                    id="outlined-required"
                                    fullWidth
                                    label="Punch String"
                                    value={modalData?.punch_string || "N/A"}
                                    name="name"
                                    readOnly
                                    sx={{ pointerEvents: "none" }}
                                />
                            </div>

                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-success px-5" onClick={() => setModalShow(false)} style={{ background: "#be2883" }}>
                                close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={hideColumn}
                onHide={() => {
                    setHideColumn(false)
                    setFilterColumn(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Hide Coulmns
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="p-2">

                            <div className="row ">
                                {columns.map((col) => (
                                    <div key={col.id} className="col-md-4 mb-3">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={col.id}
                                                checked={selectedColumns.includes(col.id)}
                                                onChange={() => handleColumnToggle(col.id)}
                                            />
                                            <label className="form-check-label" htmlFor={col.id}>
                                                {col.label}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <button className="btn btn-success bg-dark-pink" onClick={handleApply}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PartialKioskPunches