import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apis } from "../../apis";
import axios from "axios";
export const get_facility = createAsyncThunk('get_facility', async ({ token, value,is_deleted }, { rejectWithValue }) => {
    try {
        let headers = {
            token: token
        }
        const { data } = await axios.post(`${apis.GET_FACILITY}`, { company: value, is_deleted: is_deleted }, { headers })
        return data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const facilities = createSlice({
    name: 'facility',
    initialState: {
        status: false,
        loading: false,
        error: '',
        facility_data: [],
    },
    extraReducers: (builder) => {
        builder.addCase(get_facility.pending, (state, action) => {
            state.loading = false;
        });
        builder.addCase(get_facility.fulfilled, (state, action) => {
            state.facility_data = action.payload.data;
            state.status = true
            state.loading = true
        });
        builder.addCase(get_facility.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.facility_data = null;
        });
    }
})

export default facilities.reducer