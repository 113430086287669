import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Close,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { get_profile } from "../../redux/slice/profile";
import Navbar2 from "../NavBar2";
import axios from "axios";
import { apis } from "../../apis";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate()
  const [updateModel, setUpdateModel] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  useEffect(() => {
    dispatch(get_profile({ token, profile: profile?.data?._id }));
  }, []);

  const handleChangeModal = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  const handleSubmit = async () => {
    if (!newPassword) {
      return toast.error("New password is required.", {
        position: "top-right",
      });
    }
    if (!confirmPassword) {
      return toast.error("Confirm password is required.", {
        position: "top-right",
      });
    }
    if (newPassword !== confirmPassword) {
      return toast.error("Passwords do not match.", { position: "top-right" });
    }

    try {
      setLoading(true);

      await axios.put(
        apis.COMPANY_MANAGER_UPDATE_USER_PASSWORD,
        {
          userEmail: profile?.data?.email,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        {
          headers: { token: token },
        }
      );

      setUpdateModel(false);
      toast.success("Password updated successfully.", {
        position: "top-right",
      });
    } catch (error) {
      toast.error(error?.response?.data?.msg || error?.message, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Navbar2 />
      <div className="container mt-4 p-4 shadow rounded bg-white">
        <div className="d-flex align-items-center">
          <div className="text-dark" style={{ cursor: "pointer" }}>
            <span onClick={()=>{
              navigate(-1)
            }} style={{ color: "black" }}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </span>
          </div>
          <p className="admin-name-choose-sel rounded mb-0 mx-2 py-2 px-4">
            Back
          </p>
        </div>

        <div className="d-flex justify-content-center mb-4">
          <div
            className="rounded-circle border d-flex align-items-center justify-content-center"
            style={{
              width: "70px",
              height: "70px",
              backgroundColor: "#f0f0f0",
              position: "relative",
              top: "-40px",
            }}
          >
            <AccountCircle style={{ fontSize: "45px", color: "#aaa" }} />
            {/* Badge */}
            <div
              className="position-absolute top-50 start-50 translate-middle rounded-circle d-flex align-items-center justify-content-center"
              style={{
                width: "70px",
                height: "70px",
                backgroundColor: "#be2883",
                color: "white",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {profile?.data?.first_name?.substring(0, 1).toUpperCase()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label text-muted">First Name</label>
            <input
              type="text"
              className="form-control border-0 bg-light"
              value={profile?.data?.first_name || "N/A"}
              disabled
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label text-muted">Last Name</label>
            <input
              type="text"
              className="form-control border-0 bg-light"
              value={profile?.data?.last_name || "N/A"}
              disabled
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label text-muted">Email</label>
            <input
              type="email"
              className="form-control border-0 bg-light"
              value={profile?.data?.email || "N/A"}
              disabled
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label text-muted">Phone</label>
            <input
              type="text"
              className="form-control border-0 bg-light"
              value={profile?.data?.phone || "N/A"}
              disabled
            />
          </div>
          {!profile?.data?.roles?.some((role) => role.name === "admin") && (
  <div className="col-md-6 mb-3">
    <label className="form-label text-muted">
      {profile?.data?.roles?.some((role) => role.name === "company_manager")
        ? "Company Name"
        : profile?.data?.roles?.some((role) => role.name === "agency_user")
        ? "Agency Name"
        : "Facility Name"}
    </label>
    <div className="d-flex flex-wrap gap-2">
      {profile?.data?.roles?.some((role) => role.name === "company_manager") ? (
        profile?.data?.company_name?.length > 0 ? (
          <Chip label={profile?.data?.company_name} variant="filled" />
        ) : (
          <Chip label="N/A" variant="filled" />
        )
      ) : profile?.data?.roles?.some((role) => role.name === "agency_user") ? (
        profile?.data?.agency?.length > 0 ? (
          profile.data.agency.map((ag, index) => (
            <Chip key={index} label={ag.name} variant="filled" />
          ))
        ) : (
          <Chip label="N/A" variant="filled" />
        )
      ) : profile?.data?.facility?.length > 0 ? (
        profile.data.facility.map((fac, index) => (
          <Chip key={index} label={fac.name} variant="filled" />
        ))
      ) : (
        <Chip label="N/A" variant="filled" />
      )}
    </div>
  </div>
)}
          <div className="col-md-6 mb-3">
            <label className="form-label text-muted">Role</label>
            <div className="d-flex flex-wrap gap-2">
              {profile?.data?.roles?.length > 0 ? (
                profile.data.roles.map((role, index) => (
                  <Chip
                    key={index}
                    label={role.name
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                    variant="filled"
                  />
                ))
              ) : (
                <Chip label="N/A" variant="filled" />
              )}
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <Button
            style={{ backgroundColor: "#be2883" }}
            variant="contained"
            className="me-2"
            onClick={() => setUpdateModel(true)}
          >
            Change Password
          </Button>
        </div>

        <Modal open={updateModel} onClose={() => setUpdateModel(false)}>
          <div
            className="container p-4 bg-white shadow rounded"
            style={{
              maxWidth: "500px",
              margin: "100px auto",
              position: "relative",
            }}
          >
            <IconButton
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setUpdateModel(false)}
            >
              <Close />
            </IconButton>
            <h5 className="text-center">Change Password</h5>
            <div className="mb-3">
              <TextField
                fullWidth
                label="New Password"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleChangeModal}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mb-3">
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleChangeModal}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="text-center">
              <Button
                variant="contained"
                // color="success"
                sx={{backgroundColor:"#be2883"}}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProfilePage;