import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React, { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import { PiArrowClockwiseBold } from "react-icons/pi";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment-timezone";
import { DateRange } from "react-date-range";
import { Autocomplete, IconButton, InputAdornment, Skeleton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_agency } from "../../redux/slice/agency";
import Cookies from "js-cookie";
import "../../module_css/Partial_kiosk.css";
import CloseIcon from '@mui/icons-material/Close';
import { get_webhook } from '../../redux/slice/webhook/index'
import { BiShow } from "react-icons/bi";
const filterData = (data = []) => {
    return data?.map((it) => ({
        _id: it._id,
        value: it?.name || "",
    }));
};

const WebHookFilter = ({ isSkeleton, setPage, page, limit, setHideColumn, setFilterColumn }) => {
    const agency = useSelector((state) => state.agency || {});
    const profile = useSelector((state) => state.profile || {});

    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const listInit = { _id: "", value: "" };

    const [agencyList, setAgencyList] = useState([listInit]);

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const sortArrayByValue = (array = []) => {
        return array.sort((a, b) => {
            const valueA = a.value.toLowerCase();
            const valueB = b.value.toLowerCase();
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        });
    };
    const [punchString, setPunchString] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [searchFilter, setSearchFilter] = useState({});


    const handleFilterChange = (name, value) => {
        if (name == "punch_string") {
            setPunchString(value);
        }
        if (name == "response_status") {
            setResponseStatus(value);
        }
        else {
            setSearchFilter((prevFilter) => ({
                ...prevFilter,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        const list = filterData(agency.agency);
        setAgencyList(sortArrayByValue(list));
        if (!agency.status && !agency?.loading) {
            dispatch(get_agency({ token, user: profile?.data?._id }));
        }
    }, [agency.status]);

    const [debouncedPunchString, setDebouncedPunchString] = useState("");
    const [debouncedResponseStatus, setDebouncedResponseStatus] = useState("");



    const filterPayload = (page) => {
        return {
            token,
            startdate: searchFilter?.startdate || "",
            enddate: searchFilter?.enddate || "",
            agency_id: searchFilter?.agency?._id || "",
            punch_string: debouncedPunchString,
            response_status: Number(debouncedResponseStatus) || "",
            page: page,
            limit: limit,
        };
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedPunchString(punchString);
            setDebouncedResponseStatus(responseStatus)
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [punchString, responseStatus]);

    useEffect(() => {
        const newPage = 0;
        setPage(newPage);
        const filter = filterPayload(newPage); 
        dispatch(get_webhook({ filter, token }));
    }, [
        searchFilter,       
        debouncedPunchString,  
        debouncedResponseStatus, 
        limit
    ]);
    
    useEffect(() => {
        if (page !== 0) {  
            const filter = filterPayload(page);
            dispatch(get_webhook({ filter, token }));
        }
    }, [page]);

    const handleDate = (ranges) => {
        // const End = new Date(
        //   new Date(ranges.selection.endDate).getTime() + 86400000
        // );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(ranges.selection.endDate);
        setSelectionRange(ranges.selection);
        setSearchFilter({
          ...searchFilter,
          startdate: moment(stdate).format("YYYY-MM-DD"),
          enddate: moment(endate).format("YYYY-MM-DD"),
        });
      };

      const handalClearFilter = () => {
        setPage(0); 
        setSearchFilter({});
        setPunchString("");
        setResponseStatus("");
        setDebouncedPunchString("");
        setSelectionRange({
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        });
    };
    

    return (
        <div>
            <div className="d-flex justify-content-between  custom2">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        {isSkeleton ? (
                            <div className="py-2">
                                <Skeleton variant="rounded" width={140} height={30} />
                            </div>
                        ) : (
                            <h4>Webhook logs</h4>
                        )}
                        <div className="d-flex align-items-center mb-3">
                            {isSkeleton ? (
                                <Skeleton variant="rounded" width={220} height={26} />
                            ) : (
                                <div>Here is a list of all Webhook logs</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="  d-flex datefield">
                    {/* Hide column*/}
                    {isSkeleton ? (
                        <div className="px-2">
                            <Skeleton variant="rounded" width={100} height={36} />
                        </div>
                    ) : (
                        <>
                            <div className="date-fltr"
                                onClick={() => {
                                    setHideColumn(true)
                                    setFilterColumn(true)
                                }}>
                                <span>
                                    <i className="px-1" style={{ color: "#4C7153" }}>
                                        <BiShow />
                                    </i>
                                </span>
                            </div>
                        </>
                    )}
                    {isSkeleton ? (
                        <div className="px-2 ">
                            <Skeleton variant="rounded" width={100} height={36} />
                        </div>
                    ) : (
                        <div className="date-pos">
                            <div className="date-fltr">
                                <span>
                                    <MdCalendarMonth />
                                    {searchFilter?.startdate && searchFilter?.enddate ? (
                                        <span className="date-icon-span">
                                            {searchFilter?.startdate} / {searchFilter?.enddate}
                                        </span>
                                    ) : (
                                        <span className="date-icon-span">Date</span>
                                    )}
                                </span>
                            </div>
                            <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0 button">
                                <Dropdown className="dash-main-filter">
                                    <Dropdown.Toggle
                                        style={{ height: "100%", width: "183%" }}
                                    ></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <DateRange
                                            ranges={[selectionRange]}
                                            onChange={handleDate}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                    {isSkeleton ? (
                        <div className="px-2">
                            <Skeleton variant="rounded" width={100} height={36} />
                        </div>
                    ) : (
                        <>
                            <div className="date-fltr" onClick={handalClearFilter}>
                                <span>
                                    <i className="px-1" style={{ color: "#4C7153" }}>
                                        <PiArrowClockwiseBold />
                                    </i>
                                    clear filter
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="filter-right d-flex">
                {isSkeleton ? (
                    <div className="px-2">
                        <Skeleton variant="rounded" width={100} height={36} />
                    </div>
                ) : (
                    <div className="px-2 textfiled" style={{ width: "50%" }}>
                        <TextField
                            value={punchString || ""}
                            onChange={(e) => setPunchString(e.target.value)}
                            size="small"
                            variant="outlined"
                            label="Search Punch String"
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                                endAdornment: punchString ? (
                                    <InputAdornment position="end" >
                                        <IconButton onClick={() => handleFilterChange("punch_string", "")}>
                                            <CloseIcon style={{ fontSize: "18px" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </div>
                )}

                <div className="d-flex w-full" style={{ margin: "auto", marginRight: "0" }}>

                    {isSkeleton ? (
                        <div className="px-2">
                            <Skeleton variant="rounded" width={100} height={36} />
                        </div>
                    ) : (
                        <div className="px-2 textfiled">
                            <TextField
                                value={responseStatus || ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setResponseStatus(value);
                                    }
                                }}
                                size="small"
                                variant="outlined"
                                label="Status Code"
                                type="text"
                                sx={{ width: "100%" }}
                                InputProps={{
                                    endAdornment: responseStatus ? (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handleFilterChange("response_status", "")}>
                                                <CloseIcon style={{ fontSize: "18px" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                                }}
                            />
                        </div>
                    )}

            {isSkeleton ? (
                <div className="px-2" >
                    <Skeleton variant="rounded" width={100} height={36} />
                </div>
            ) : (
                <div>
                    <Autocomplete
                        id="agency-autocomplete"
                        size="small"
                        options={agencyList}
                        sx={{ width: 220 }}
                        value={searchFilter?.agency || listInit}
                        onChange={(_, value) => handleFilterChange("agency", value)}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={(params) => (
                            <TextField {...params} label="Agency"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: searchFilter?.agency && searchFilter?.agency.value ? (
                                        <InputAdornment position="end" style={{ position: "absolute", right: "0" }} >
                                            <IconButton onClick={() => handleFilterChange("agency", { id: "", value: "" })}>
                                                <CloseIcon style={{ fontSize: "18px" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                                }}

                            />
                        )}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                style={{ cursor: "pointer" }}
                                key={option._id}
                                className="py-1 ps-3 menu-drop-item"    
                            >
                                {" "}
                                {option.value}{" "}
                            </li>
                        )}
                    />
                </div>
            )}
            </div>
        </div>
        </div >
    );
};
export default WebHookFilter;