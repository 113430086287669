import React, { useState, useEffect } from "react";
import NavBar2 from "../NavBar2";
import { apis } from "../../apis";
import axios from "axios";
import Cookies from "js-cookie";
import Adminaside from "../../components/asides/Adminaside";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_company } from "../../redux/slice/createcompany";
import AdminHeader from "../../components/comman/admin_header";
import { Col, Row } from "react-bootstrap";
import { get_twilio } from "../../redux/slice/createtwilio";
import "../../module_css/createtwiliocreds.css";
import MultipleSelect from "../../components/input/MultipleSelect";
import { get_facility } from "../../redux/slice/facility";
import { get_device } from "../../redux/slice/device";
import { get_user } from "../../redux/slice/user";
import { get_client } from "../../redux/slice/client";

export default function CreateClient() {
  let token = Cookies.get("token");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state.user);
  const facility = useSelector((state) => state.facility);
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState("");
  const [error, setError] = useState(formData);
  const [successToast, setSuccessToast] = useState(false);
  const [errToast, setErrToast] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!facility?.status && !facility?.loading)
      dispatch(get_facility({ token }));
    if(!user?.status)
        dispatch(get_user({ token }));
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.userId) {
          toast.error("User is required.");
          return;
        }
    
        setLoading(true);
        setResError("");
    
        try {
          const headers = { token };
          const res = await axios.post(apis.CREATE_CLIENT_CREDENTIAL, formData, { headers });
          if (res.status === 200 || res.status === 201) {
            toast.success("Client created successfully");
            navigate("/admin/client/dashboard");
            dispatch(get_client({ token }))
            setFormData(formInit);
          }
        } catch (error) {
          setResError(error?.response?.data?.message || error.message);
          toast.error(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
    };
    

  const handleSelect = (selectedUser) => {
        if (selectedUser) {
          setFormData({
            ...formData,
            userId: selectedUser._id,
            firstName: selectedUser.first_name,
            lastName: selectedUser.last_name,
          });
        } else {
          setFormData({
            ...formData,
            userId: "",
            firstName: "",
            lastName: "",
          });
        }
    };
    
      
  return (
    <>
      <div className="admin-dashboard">
        <div className="admin-nav">
          <NavBar2 />
        </div>
        <div className="admin-container">
          <div className="aside text-center align-item-center">
            <Adminaside />
          </div>
          <AdminHeader
            backTitle="Dashboard"
            backPath="/admin"
            action_path="/admin/client/dashboard"
            action="All Client"
          />
          <div
            className="create-user twiliouser
        "
          >
            <form onSubmit={handleSubmit}>
              <h1 className="text-center mb-3">Create Client</h1>
              <div className="container">
                <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="py-2"
                  >
             <Autocomplete
  options={
    Array.isArray(user?.user_data)
      ? [...user?.user_data].sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
      : []
  }
  getOptionLabel={(option) => `${option?.first_name || ""} ${option?.last_name || ""}`}
  value={
    formData.userId
      ? user?.user_data?.find((u) => u._id === formData.userId) || null
      : null
  }
  onChange={(event, newValue) => {
    handleSelect(newValue || null);
  }}
  isOptionEqualToValue={(option, value) => option?._id === value?._id}
  renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
/>


                  </Col>
                </Row>
                <div className="mt-2 d-flex justify-content-center">
                  <button
                    type={loading ? "button" : "submit"}
                    className="btn btn-success bg-dark-pink"
                  >
                    {loading ? "Loading..." : "Create Client"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
