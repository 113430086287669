// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="text-center">
        <h1 className="display-1 text-danger">404</h1>
        <p className="lead">Oops! The page you're looking for doesn't exist.</p>
        <Link to="/landing-page" className="btn btn-success">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
