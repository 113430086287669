import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { convertToMMDDYYYYWithTime } from "../../../utils/Helper";

//function to get current date and time
function formatDate() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}-${formattedHours}:${minutes}${ampm}`;
  return formattedDate;
}

const ExportJSON = ({
  data,
  selectedField = [],
  isSpecifiedFields = false,
  setIsSpecificFieldsModal,handleExportBtn,exportLoading
}) => {
  const [csvData, setCsvData] = useState([]);
  const [filename, setFileName] = useState("");
  const all_csv_shifts = useSelector((state) => state.all_csv_shifts);
  const headers = [
    "ClassName",
    // "FullName",
    "FirstName", 
    "LastName",
    "Email",
    "ScanInTime",
    "ScanOutTime",
    "CheckInTime(MM/DD/YYYY)",
    "CheckOutTime(MM/DD/YYYY)",
    "ManualCheckOutTime",
    "HoursInFacility",
    "Phone",
    "Address",
    "VisitType",
    "ResidentName",
    "RoomNumber",
    "KioskName",
    "ShiftId",
    "FacilityId",
    "LocationId",
    "AgencyId",
    "Agency",
    "ShiftTime",
    "PunchInString",
    "PunchOutString",
    "JobFunction",
    "Vendor",
    "Department",
    "Vaccination",
    "CheckInTemperature",
    "CheckOutTemperature",
    "MidShiftTemperatures",
    "Do you have fever or symptoms of a respiratory infection, such as a cough and sore throat?",
    "Have you traveled internationally within the last 14 days to restricted countries?",
    "Have you had contact with someone with known or suspected COVID-19?",
  ];
  useEffect(() => {
    const dataList = data?.map((it) => {
      return {
        ClassName: it?.facility_id?.name,
        // ShiftId: it?._id,
        // FullName: `${it?.caregiver?.lastName} ${it?.caregiver?.firstName}`,
        FirstName: `${it?.caregiver?.firstName}`,
        LastName: `${it?.caregiver?.lastName}`,
        Email: "",
        ScanInTime:convertToMMDDYYYYWithTime(it?.scan_time),
        ScanOutTime: it?.scan_out_time ? convertToMMDDYYYYWithTime(it?.scan_out_time) : it?.shift_out_id?.utcTime ? convertToMMDDYYYYWithTime(it?.shift_out_id?.utcTime): "N/A" ,
        "CheckInTime(MM/DD/YYYY)":it?.startShift?.date&&it?.startShift?.time? `${it?.startShift?.date} : ${it?.startShift?.time}`:"N/A",
        "CheckOutTime(MM/DD/YYYY)":it?.endShift?.date&&it?.endShift?.time? `${it?.endShift?.date} : ${it?.endShift?.time}`:"N/A",
        ManualCheckOutTime: "",
        HoursInFacility: `${it?.duration}`,
        Phone: it?.caregiver?.phone,
        Address: it?.facility_id?.address,
        VisitType: "agency",
        ResidentName: "",
        RoomNumber: "",
        KioskName: it?.facility_id?.name,
        ShiftId: it?.agency_shift_id,
        FacilityId:it?.facility_id?._id,
        LocationId:it?.facility_id?.locationId,
        AgencyId:it?.agency?._id,
        Agency: it?.agency?.name,
        ShiftTime: "",
        PunchInString:it?.shift_in_id?.punch_string,
        PunchOutString:it?.shift_out_id?.punch_string,
        JobFunction: it?.position,
        Vendor: "",
        Department: "",
        Vaccination: "",
        CheckInTemperature: "",
        CheckOutTemperature: "",
        MidShiftTemperatures: "",
        "Do you have fever or symptoms of a respiratory infection, such as a cough and sore throat?":
          "",
        "Have you traveled internationally within the last 14 days to restricted countries?":
          "",
        "Have you had contact with someone with known or suspected COVID-19?":
          "",
      };
    });

    const specificFieldDataList = data?.map((it) => {
      return {
        "Facility name": it?.facility_id?.name || "N/A",
        "Shift id": it?.agency_shift_id || "N/A",
        "Check in": it?.startShift?.date && it?.startShift?.time 
          ? `${it.startShift.date} : ${it.startShift.time}` 
          : "N/A",
        "Check out": it?.endShift?.date && it?.endShift?.time 
          ? `${it.endShift.date} : ${it.endShift.time}` 
          : "N/A",
        "Facility hours": it?.duration ? `${it.duration}` : "N/A",
        Phone: it?.caregiver?.phone || "N/A",
        Address: it?.facility_id?.address || "N/A",
        Agency: it?.agency?.name||"N/A",
        "Shift time": "",
        Position: it?.position || "N/A",
      };
    });
    


    if (data?.length) {
      const date = formatDate();
      const file = `Report-${date}.csv`;
      setFileName(file);
      setCsvData(dataList);
    } else {
      const date = formatDate();
      const file = `Report-${date}.csv`;
      setFileName(file);
      setCsvData("no-data.csv");
    }
  }, [data]);

  // const handleExport = async () => {
  //   setTriggerDownload(false); // Reset the trigger
  //   try {
  //     await handleExportBtn(); // Assuming handleExportBtn is an async function that handles the API call
  //     setTriggerDownload(true); // Trigger the download after the API call is successful
  //   } catch (error) {
  //     console.error("Export failed:", error);
  //   }
  // };
  return (

    // <CSVLink
    //   data={csvData}
    //   headers={isSpecifiedFields ? selectedField : headers}
    //   filename={filename}
    //   style={{ textDecoration: "none" }}
    // >
    //   <div className="date-fltr ">
    //     {isSpecifiedFields ? <span className="p-2" onClick={() => setIsSpecificFieldsModal(false)}>
    //       <i className="px-2">
    //         <CiExport />
    //       </i>
    //       Export
    //     </span> :
    //       <span className="p-2">
    //         <i className="px-2">
    //           <CiExport />
    //         </i>
    //         Export
    //       </span>
    //     }

    //   </div>
    // </CSVLink>

isSpecifiedFields ? (
  <CSVLink
    data={csvData}
    headers={ selectedField} 
    filename={filename}
    style={{ textDecoration: "none", pointerEvents: selectedField.length > 0 ? "auto" : "none" }}
    onClick={(event) => {
      if (selectedField.length === 0) {
        event.preventDefault(); 
      } else {
        setIsSpecificFieldsModal(false);
      }
    }}
  >
    <div className="date-fltr" onClick={handleExportBtn}>
      <span
        className={`p-2 ${selectedField.length > 0 ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}`}
      >
        <i className="px-2">
          <CiExport />
        </i>
        {exportLoading ? "Loading..." : "Export"}
      </span>
    </div>
  </CSVLink>
) : (
  <CSVLink
    data={csvData}
    headers={headers}
    filename={filename}
    style={{ textDecoration: "none" }}
  >
    <div className="date-fltr">
      <span className="p-2">
        <i className="px-2">
          <CiExport />
        </i>
        Export
      </span>
    </div>
  </CSVLink>
)   
   )
};

export default ExportJSON;