// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./App.css";
import Login from "./pages/Login";
// import Navbar from "./pages/Navbar";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import Dashboard from "./pages/Dashboard";
import Private_route from "./Private_route";
// import Main_board from "./pages/Main_board";
// import Members_section from "./pages/Members_section";
// import Sidebar from "./pages/Sidebar";
import ContactTexting from "./pages/ContactTexting";
import MassTexting from "./pages/MassTexting";
import EnterPage from "./pages/EnterPage";
import MsgRequest from "./pages/MsgRequest";
import Inbox from "./pages/Inbox";
import AllPatients from "./pages/AllPatients";
import NoAuthPuch from "./pages/NoAuthPuch";
import Shift from "./pages/shift";
import Admin from "./pages/adminpage/Admin"
import CreateUser from "./pages/adminpage/CreateUser";
import CreateCompany from "./pages/adminpage/CreateCompany";
import CreateFacility from "./pages/adminpage/CreateFacility";
import Members_section from "./pages/Members_section";
import CreateCaregiver from "./pages/adminpage/CreateCaregiver";
import Userdashboard from "./pages/adminpage/Userdashboard";
import Companydashboard from "./pages/adminpage/Companydashboard";
import Facilitydashboard from "./pages/adminpage/Facilitydashboard";
import Caregiverdashboard from "./pages/adminpage/Caregiverdashboard";
import CreateAgency from "./pages/adminpage/CreateAgency";
import Agencydashboard from "./pages/adminpage/Agencydashboard";
import Roledashboard from "./pages/adminpage/Role";
import CreateRole from "./pages/adminpage/CreateRoles";
import CreateAgencyWebhook from "./pages/adminpage/CreateAgencyWebhook";
import AgencyWebhookdashboard from "./pages/adminpage/AgenncyWebhookDashboard";
import ShiftDashboard from "./pages/shift";
import CreateFacilityWebhook from "./pages/adminpage/CreateFacilityWebhook";
import FacilityWebhookdashboard from "./pages/adminpage/FacilityWebhookDashboard";
import CreateIntegrationFacilityMapping from "./pages/adminpage/IntegrationFacilityMapping/create";
import IntegrationFacilityMappingDashboard from "./pages/adminpage/IntegrationFacilityMapping/dashboard";
import SystemIntegration from "./pages/adminpage/system-integration";
import PartialKioskPunches from "./pages/partial-kiosk-punches";
import SendOtp from "./pages/SendOtp";
import VerifyOtp from "./pages/VerifyOtp";
import ResetPassword from "./pages/ResetPassword";
import Registeruser from "./pages/Registeruser";
import Mass from "./pages/Mass";
import BroadCastHistory from "./pages/BroadCastHistory";
import CreateTwilioCreds from "./pages/adminpage/CreateTwilioCreds";
import TwilioCreds from "./pages/adminpage/TwilioCreds";
import SingleBroadcast from "./pages/SingleBroadcast";
import TwilioFacilityPhone from "./pages/adminpage/TwilioFacilityPhone";
import CreateTwilioFacilityPhone from "./pages/adminpage/CreatetwilioFacilityPhone";
import NotFound from "./components/notfound/Notfound";
import { useSelector } from "react-redux";
import WebHook from "./pages/webhook";
import CreateDevices from "./pages/adminpage/CreateDevice";
import Devicedashboard from "./pages/adminpage/Devicedashboard";
import CompanyUserdashboard from "./pages/adminpage/CompanyUserDashboard";
import ProfilePage from "./pages/adminpage/ProfilePage";
import Clientdashboard from "./pages/adminpage/ClientDashboard";
import CreateClient from "./pages/adminpage/CreateClient";

function App() {
  const profile = useSelector((state) => state.profile);
  const isAdmin = profile?.data?.roles?.some(role => role.name === 'admin');
  const isCompanyManager = profile?.data?.roles?.some(role => role.name === 'company_manager');


  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navbar /> */}

        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/registration" element={<Registeruser />} /> */}
          <Route path="/forget-password" element={<SendOtp />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contact" element={<Private_route role="all" componant={<ContactTexting />} />} />
          <Route path="/broadcast-history" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <BroadCastHistory /> :
            <Navigate to={"/landing-page"} />} />} />
          <Route path="/broadcast/:broadcast_id" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <SingleBroadcast /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/mass" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <Mass /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/landing-page" element={<Private_route role="all" componant={<EnterPage />} />} />
          <Route path="/profile" element={<Private_route role="all" componant={<ProfilePage />} />} />
          <Route path="/message-request" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <MsgRequest /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/inbox" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <Inbox /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/all-patient" element={<Private_route role="all" componant={profile?.data?.is_subscriptable_text ? <AllPatients /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/dashboard" element={<Private_route role="all" componant={profile?.data?.portal_access ? <ShiftDashboard /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/partial-kiosk-punches" element={<Private_route role="all" componant={isAdmin ? <PartialKioskPunches /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/webhook-logs" element={<Private_route role="all" componant={isAdmin ? <WebHook /> : <Navigate to={"/landing-page"} />} />} />

          {/* <Route path="/shift" element={<Private_route role="all" componant={<Punches />} />} /> */}
          {/* <Route path="/punches" element={<Private_route role="all" componant={<Dashboard />} />} /> */}

          <Route path="/admin" element={<Private_route role="admin" componant={isAdmin ? <Admin /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/twiliocreds" element={<Private_route role="admin" componant={isAdmin ? <TwilioCreds /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-user" element={<Private_route role="admin" componant={isAdmin ? <CreateUser /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/device" element={<CreateDevices role="admin" componant={isAdmin ? <CreateUser /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/client" element={<CreateClient role="admin" componant={isAdmin ? <CreateUser /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/user/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Userdashboard /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/company-users/dashboard" element={<Private_route role="company_manager" componant={isCompanyManager ? <CompanyUserdashboard /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/twiliofacilityphone" element={<Private_route role="admin" componant={isAdmin ? <TwilioFacilityPhone /> : <Navigate to={"/landing-page"} />} />} />


          <Route path="/admin/create-company" element={<Private_route role="admin" componant={isAdmin ? <CreateCompany /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/create-twilio" element={<Private_route role="admin" componant={isAdmin ? <CreateTwilioCreds /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/company/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Companydashboard /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/create-twiliofacilityphone" element={<Private_route role="admin" componant={isAdmin ? <CreateTwilioFacilityPhone /> : <Navigate to={"/landing-page"} />} />} />


          <Route path="/admin/create-facility" element={<Private_route role="admin" componant={isAdmin ? <CreateFacility /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/facility/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Facilitydashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-caregiver" element={<Private_route role="admin" componant={isAdmin ? <CreateCaregiver /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/caregiver/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Caregiverdashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/device/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Devicedashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/client/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Clientdashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-Agency" element={<Private_route role="admin" componant={isAdmin ? <CreateAgency /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/Agency/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Agencydashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-role" element={<Private_route role="admin" componant={isAdmin ? <CreateRole /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/role/dashboard" element={<Private_route role="admin" componant={isAdmin ? <Roledashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-agencywebhook" element={<Private_route role="admin" componant={isAdmin ? <CreateAgencyWebhook /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/agencywebhook/dashboard" element={<Private_route role="admin" componant={isAdmin ? <AgencyWebhookdashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/admin/create-facilitywebhook" element={<Private_route role="admin" componant={isAdmin ? <CreateFacilityWebhook /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/facilitywebhook/dashboard" element={<Private_route role="admin" componant={isAdmin ? <FacilityWebhookdashboard /> : <Navigate to={"/landing-page"} />} />} />

          <Route path="/non-kiosk-punch/:facilityId/" element={isAdmin ? <NoAuthPuch /> : <Navigate to={"/landing-page"} />} />

          {/* Integration Facility Mapping */}
          <Route path="/admin/integration-facility-mapping/create" element={<Private_route role="admin" componant={isAdmin ? <CreateIntegrationFacilityMapping /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/integration-facility-mapping" element={<Private_route role="admin" componant={isAdmin ? <IntegrationFacilityMappingDashboard /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="/admin/system-integration" element={<Private_route role="admin" componant={isAdmin ? <SystemIntegration /> : <Navigate to={"/landing-page"} />} />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>

      {/* <Login /> */}
    </div>
  );
}

export default App;
