
export const remove_duplicate_msg = (msg_arr) => {
    const uniquedates = [];

    const filteredMessagesArray = msg_arr.filter((message) => {
        const { year, month, day } = message._id;

        const dateKey = `${year}-${month}-${day}`;

        if (!uniquedates.includes(dateKey)) {
            uniquedates.push(dateKey);
            return true;
        }
        return false;
    });
    return filteredMessagesArray
}

export const mergeMessagesByDate = (messages) => {
    const mergedMessages = {};

    messages.forEach((dayData) => {
        const { _id, messages } = dayData;
        const { year, month, day } = _id;
        const key = `${year}-${month}-${day}`;
        if (!mergedMessages[key]) {
            mergedMessages[key] = {
                _id: { year, month, day },
                messages: [],
            };
        }

        mergedMessages[key].messages = mergedMessages[key].messages.concat(messages);
    });

    return Object.values(mergedMessages);
}


export function convertToTimeZone(utcDateTimeStr, timeZone) {
    try {
        // Parse the UTC date-time string
        const utcDate = new Date(utcDateTimeStr);
        
        // Check if the date is valid
        if (isNaN(utcDate.getTime())) {
            throw new Error("Invalid UTC date-time string");
        }

       // validatezone 
       const validTimeZone = (timeZone===null || timeZone=== undefined || timeZone==="")  ? "America/New_York" : timeZone;

        // Convert to the specified timezone
        const options = {
            timeZone:validTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            // hour12: false
            hour12: true 
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(utcDate);
        
        // Extract formatted values
        const dateObj = {};
        parts.forEach(({ type, value }) => {
            dateObj[type] = value;
        });
        
        // Return formatted date-time string
        return `${dateObj.month}/${dateObj.day}/${dateObj.year} ${dateObj.hour}:${dateObj.minute} ${dateObj.dayPeriod}`;

    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export function convertToMMDDYYYYWithTime(timestamp) {
    // Check if the timestamp is valid
    if (!timestamp || isNaN(new Date(timestamp))) {
      return "N/A"; // Return "N/A" for invalid or missing timestamps
    }
  
    // Create a Date object from the timestamp
    const date = new Date(timestamp);
  
    // Extract month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    // Extract hours, minutes, and determine AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // Handle midnight (0 hours) as 12 AM
  
    // Return the formatted date and time as "mm/dd/yyyy : hh:mm AM/PM"
    return `${month}/${day}/${year}  ${hours}:${minutes} ${ampm}`;
  }


  export function convertUtcToMMDDYYYY(timestamp) {
    if (!timestamp || isNaN(new Date(timestamp))) {
        return "N/A"; // Handle invalid timestamps
    }

    const date = new Date(timestamp);

    // Extract month, day, and year in UTC
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    // Extract hours, minutes, and determine AM/PM in UTC
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Return formatted date
    return `${month}/${day}/${year}  ${hours}:${minutes} ${ampm}`;
}
