import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";

const CustomSearchField = ({
  filterData,
  handleOptionClick,
  name,
  error,
  helperText,
  label,
  isPlaceHolder,
}) => {
  const [showInputOptions, setShowInputOptions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [updatedOption, setUpdatedOption] = useState();

  useEffect(() => {
    if (inputValue === "") {
      setUpdatedOption(filterData);
    } else {
      const updateName = filterData?.filter((data) => {
        // const employeeName = `${data.firstName} ${data.lastName}`;

        const employeeName =
          isPlaceHolder === "Employee"
            ? `${data?.firstName} ${data?.lastName}`
            : isPlaceHolder === "Agency"
            ? data?.name
            : isPlaceHolder === "Facility"
            ? data?.name
            : "Select";
        return employeeName
          .toLowerCase()
          ?.trim()
          ?.includes(inputValue.toLowerCase());
      });
      setUpdatedOption(updateName);
    }
  }, [filterData, inputValue]);
  return (
    <Box
      onBlur={() => {
        setShowInputOptions(false);
      }}
    >
      <TextField
        label={label}
        value={inputValue}
        fullWidth
        onChange={(e) => setInputValue(e.target.value)}
        onClick={() => setShowInputOptions(true)}
        InputProps={{
          autoComplete: "off",
        }}
        error={!!error}
        helperText={helperText}
      />
      {updatedOption?.length > 0 && showInputOptions && (
        <ul
          style={{
            position: "absolute",
            width: "27%",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginTop: "5px",
            listStyle: "none",
            padding: "5px 0",
            maxHeight: "400px",
            overflowY: "auto",
            zIndex: 1000,
          }}
          className="custom_select"
        >
          {updatedOption?.map((option, index) => {
            const optionName =
              isPlaceHolder === "Employee"
                ? `${option?.firstName} ${option?.lastName}`
                : isPlaceHolder === "Agency"
                ? option?.name
                : isPlaceHolder === "Facility"
                ? option?.name
                : "Select";
            return (
              <li
                key={index}
                onMouseDown={() => {
                  handleOptionClick(option, name);
                  setInputValue(optionName);
                }}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  fontSize: "14px",
                  textAlign: "left",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#F0F0F0")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#fff")}
              >
                {optionName}
              </li>
            );
          })}
        </ul>
      )}
    </Box>
  );
};

export default CustomSearchField;
